<script lang="ts" setup>
    import { onMounted, reactive, ref, provide } from "vue";
    import "primevue/resources/themes/saga-blue/theme.css";
    import "primevue/resources/primevue.min.css";
    import "primeicons/primeicons.css";
    import {
        FilterMatchMode,
        FilterOperator
    } from "primevue/api";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    // import InputSwitch from "primevue/inputswitch";
    import InputText from "primevue/inputtext";
    import { useAuthStore } from "@/stores/authStore";
    import { useEmployeesStore } from "@/stores/employeesStore";
    import { useHelperStore } from "@/stores/helperStore";
    import { useUiStore } from "@/stores/uiStore";
    // import EmployeeInterface from "@/pages/interfaces/employeesInterface";
    import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
    import Button from "@/base-components/Button";
    import BaseButton from "@/base-components/Button/BaseButton.vue";
    import { FormInput } from "@/base-components/Form";
    import Lucide from "@/base-components/Lucide";
    import ConfirmModal from "@/base-components/Dialog/ConfirmModal.vue";
    import FormEmployee from "@/pages/forms/FormEmployee.vue";
    import FormSlider from "@/base-components/Slider/FormSlider.vue";
    import { FormSwitch } from "@/base-components/Form";
    // import { Menu } from "@/base-components/Headless";
    import EmployeeIncompleteConfigurationModal from "@/pages/components/employees/EmployeeIncompleteConfigurationModal.vue";
    import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";
    import ImportEmployees from "@/pages/components/employees/ImportEmployees.vue";
    import ImportEmployeeShift from "@/pages/components/employees/ImportEmployeeShift.vue";
    import ImportInvoiceInformation from "@/pages/components/employees/ImportInvoiceInformation.vue";
    // import ImportPayrollItems from "@/pages/components/employees/ImportPayrollItems.vue";
    import Tippy from "@/base-components/Tippy/Tippy.vue";
    import NoDataFound from "@/base-components/Label/NoDataFound.vue";
    import PrimevueTablePreloader from "@/base-components/Loaders/PrimevueTablePreloader.vue";
    import Badge from "@/base-components/Badge/Badge.vue";
    import { capitalizeFirstLetter } from "@/utils/helper";
    import TableIcon from "@/base-components/Icon/TableIcon.vue";
    import ConfirmDialog from "@/base-components/Dialog/ConfirmDialog.vue";
    import { messageAlerts } from "@/utils/helper";
    import { BaseNotification, NotificationElement } from "@/base-components/Notification";
    import { debounce } from "lodash";
    import DataTableSkeletonWithColumns from "@/base-components/Skeleton/DataTableSkeletonWithColumns.vue";

    interface EmployeeData {
        id: number;
    }

    interface EmployeeApiData {
        data: any;
    }

    const AuthStore = useAuthStore();
    const EmployeeStore = useEmployeesStore();
    const HelperStore = useHelperStore();
    const UiStore = useUiStore();

    const initialLazyParams = {
        first: 1,
        rows: 30,
        sortField: null,
        sortOrder: undefined,
        filters: {
            global: {
                value: "",
                matchMode: FilterMatchMode.CONTAINS
            },
            position: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            name: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            personal_email: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            primary_work_location: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            "employeeStatusTypeGroup.name": {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            "shift.shift.name": {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            timezone_id: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            "client.legal_business_name": {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            }
        }
    };

    let statesOptionsDetails = {};
    let timezoneOptionsDetails = {};
    const employeeApiData = reactive<EmployeeApiData>({
        data: []
    });

    const showInactives = ref(false);

    const showClearFilter = ref(false);
    const isEmployeeFormSliderOut = ref(false);
    const alertModelSettings = reactive({
        isDisplayed: false,
        mode: "",
        subject: "",
        message: ""
    });

    const successNotification = ref<NotificationElement>();

    provide("bind[successNotification]", (el: NotificationElement) => {
        successNotification.value = el;
    });

    const showSuccessNotification = () => {
        successNotification.value?.showToast();
    };

    const allIncompleteConfiguration = ref(false);
    const incompleteConfiguration = ref<string[]>([]);
    const incompleteConfigurationOptions = ref([
        {
            label: "Employee Base Cost",
            value: "employeeCurrentCostHistory"
        },
        {
            label: "Employee Nonrefundable",
            value: "employeeNonrefundableHistory"
        },
        { label: "Employee RE Fee", value: "employeeReFee" },
        { label: "Employee Shift", value: "shift" },
        { label: "Employee Schedule", value: "schedules" }
    ]);

    const employees = ref();

    const selectedEmployees = ref();

    const selectAll = ref(false);

    const loading = ref(true);

    const totalRecords = ref(0);

    const lazyParams = ref<LazyParamsInterface>({
        rows: initialLazyParams.rows
    });

    const filters = ref(initialLazyParams.filters);

    const dt = ref();

    let timezone = ref();

    const columns = ref([
        { field: "name", header: "Employee Name" },
        { field: "position", header: "Position" },
        { field: "personal_email", header: "Email" },
        { field: "primary_work_location", header: "Location" }
    ]);

    const searchableCols = ref([
        "name",
        "position",
        "personal_email",
        "personal_phone",
        "work_email",
        "client_email",
        "primary_work_location",
        "secondary_work_location",
        "shift_schedule",
        "employeeStatusTypeGroup.name"
    ]);
    const searchPlaceholder = ref("Search: Name, Position, Email, Location, Status");
    const searchLoading = ref(false);

    const formEmployeeSettings = reactive({
        id: 0
    });

    const employeeId = ref(0);
    const deleteId = ref(0);
    const showConfirmDialog = ref(false);

    onMounted(async () => {
        UiStore.setBreadcrumb("All Employees");

        filters.value = initialLazyParams.filters;
        lazyParams.value = {
            ...initialLazyParams,
            filters: filters.value
        };

        await loadLazyData();

        if (HelperStore.timezones.length === 0) {
            await HelperStore.fillTimezones();
        }
    });

    const closeSlider = async () => {
        employeeId.value = 0;
        isEmployeeFormSliderOut.value = false;

        await loadLazyData();
    };

    const createNew = () => {
        isEmployeeFormSliderOut.value = true;
        employeeId.value = 0;
    };

    const updateEmployee = async (data: EmployeeData) => {
        employeeId.value = data.id;
        employeeApiData.data = data;
        console.log(employeeApiData.data);
        isEmployeeFormSliderOut.value = true;
    };
    const confirmDelete = (data: { id: number }) => {
        deleteId.value = data.id;
        setConfirmDialog(true);
    };

    const setConfirmDialog = (value: boolean) => {
        showConfirmDialog.value = value;
    };

    const processDelete = (event: any) => {
        alertModelSettings.isDisplayed = false;
        if (event) {
            console.log(deleteId.value);
            EmployeeStore.delete(deleteId.value).then(
                (response: any) => {
                    if (response.status === "success") {
                        loadLazyData();
                        deleteId.value = 0;
                        showSuccessNotification();
                    }
                }
            );
        }
        setConfirmDialog(false);
    };

    const deleteEmployee = async (data: { id: any }) => {
        console.log("deleteEmployee", data);
        await EmployeeStore.delete(data.id);
        await loadLazyData();
    };

    const updateShowInactives = async () => {
        loading.value = true;
        showInactives.value = !showInactives.value;
        await loadLazyData();
        loading.value = false;
    };

    const handleFetchEmployees = () => {
        loadLazyData();
    };

    const loadLazyData = async () => {
        console.log("loadLazyData");
        loading.value = true;
        const params = {
            show_inactives: showInactives.value,
            dt_params: JSON.stringify(lazyParams.value),
            searchable_columns: JSON.stringify(
                searchableCols.value
            ),
            incomplete_configuration:
                incompleteConfiguration.value
        };

        await EmployeeStore.getAllEmployeesForTable(params);
        employees.value = EmployeeStore.employeesTableData.data;
        if (
            EmployeeStore.employeesTableData.total !== undefined
        ) {
            totalRecords.value =
                EmployeeStore.employeesTableData.total;
        }
        console.log("total records", totalRecords.value);
        loading.value = false;
    };

    const onPage = async (event: { filters?: any }) => {
        lazyParams.value = event;
        await loadLazyData();
    };

    const onSort = async (event: any) => {
        searchPlaceholder.value = "Sort: " + event.sortField;
        console.log("onSort");
        showClearFilter.value = true;
        lazyParams.value = event;
        await loadLazyData();
    };

    const onFilter = async () => {
        searchPlaceholder.value = "Filter: Custom Search";
        showClearFilter.value = true;
        lazyParams.value.filters = filters.value;
        await loadLazyData();
    };

    const globalSearchHandler = debounce((event: any) => {
        searchLoading.value = true;
        showClearFilter.value = filters.value.global.value !== "";
        lazyParams.value.filters = filters.value;
        loadLazyData();
        searchLoading.value = false;
    },500);

    const onSelectAllChange = (event: { checked: any }) => {
        selectAll.value = event.checked;
    };

    const onRowSelect = () => {
        selectAll.value =
            selectedEmployees.value.length ===
            totalRecords.value;
    };

    const onRowUnselect = () => {
        selectAll.value = false;
    };

    const handleSelectIncompleteConfiguration = () => {
        allIncompleteConfiguration.value =
            incompleteConfiguration.value.length ===
            incompleteConfigurationOptions.value.length;
    };

    const handleSelectAllIncompleteConfiguration = () => {
        if (allIncompleteConfiguration.value) {
            incompleteConfiguration.value =
                incompleteConfigurationOptions.value.map(
                    (config) => config.value
                );
        } else {
            incompleteConfiguration.value = [];
        }
    };

    const onClearIncompleteConfiguration = () => {
        incompleteConfiguration.value = [];
        allIncompleteConfiguration.value = false;

        loadLazyData();
    };

    const formEmployeeStatus = (status: any) => {
        if (status) {
            loadLazyData();
        }
    };

    const resetGlobalFilter = () => {
        showClearFilter.value = false;
        searchPlaceholder.value = "Search: Name, Position, Email, Location, Status";
        filters.value = initialLazyParams.filters;
        // reset search input
        filters.value.global.value = "";
        lazyParams.value = {
            ...initialLazyParams,
            filters: filters.value
        };

        incompleteConfiguration.value = [];

        loadLazyData();
    };
</script>
<template>
    <div
        v-if="
            Object.values(
                AuthStore.activatedPermissionsName
            ).includes('can view all employees')
        "
        class="w-full header-margin"
    >
        <div class="w-full">
            <div class="flex flex-col justify-between gap-3 lg:flex-row w-full sm:w-auto">
                <div class="flex flex-row gap-2">
                    <div>
                        <BaseButton
                            v-if="
                                Object.values(
                                    AuthStore.activatedPermissionsName
                                ).includes('can create employees')
                            "
                            variant="primary"
                            icon="Plus"
                            label="Add New Employee"
                            class="hidden sm:flex whitespace-nowrap"
                            @click="createNew"
                        />
                        <BaseButton
                            variant="primary"
                            kind="icon"
                            class="sm:hidden"
                            @click="createNew"
                            icon="Plus"
                            tippy-content="Add New Employee"
                        >
                        </BaseButton>
                    </div>

                    <ImportEmployees
                        v-if="
                            AuthStore.role.includes('admin') ||
                            AuthStore.role.includes('hr')
                        "
                        @fetchEmployees="handleFetchEmployees" />
                    <ImportEmployeeShift
                        v-if="
                            AuthStore.role.includes('admin') ||
                            AuthStore.role.includes('hr')
                        "
                    />
                    <!--                <ImportPayrollItems />-->
                    <ImportInvoiceInformation
                        v-if="
                            AuthStore.role.includes('admin') ||
                            AuthStore.role.includes(
                                'finance'
                            ) ||
                            AuthStore.role.includes('payroll')
                        "
                        @fetchEmployees="handleFetchEmployees"
                    />
                </div>
                <div class="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between lg:w-full">
                    <div class="align-center flex flex-row gap-2 items-center ml-auto sm:ml-0">
                        <FormSwitch>
                            <FormSwitch.Input
                                type="checkbox"
                                @change="updateShowInactives"
                            />
                        </FormSwitch>

                        <label class="font-medium text-sm md:text-xs">Show Inactive / Terminated</label>
                    </div>

                    <div class="w-full sm:w-60">
                        <FormInput
                            id="global-filter"
                            v-model="filters.global.value"
                            :value="filters.global.value"
                            class="pr-10"
                            :placeholder="searchPlaceholder"
                            type="text"
                            for="search"
                            :loading="searchLoading"
                            :close="showClearFilter"
                            rightIcon="Search"
                            @keyup="globalSearchHandler($event)"
                            @close="resetGlobalFilter"
                        />
                    </div>
                </div>
            </div>
            <DataTableSkeletonWithColumns
                v-if="loading"
                :columns="10"

                class="mt-5"
            />
            <DataTable
                v-else
                ref="dt"
                v-model:filters="filters"
                v-model:selection="selectedEmployees"
                :first="lazyParams.first"
                :globalFilterFields="searchableCols"
                :lazy="true"
                :paginator="true"
                :rows="lazyParams.rows"
                :rowsPerPageOptions="[30, 50, 100]"
                :sortField="lazyParams.sortField"
                :sortOrder="lazyParams.sortOrder"
                :totalRecords="totalRecords"
                :value="employees"
                class="mt-4 text-xs rounded-lg shadow-lg p-datatable-sm"
                dataKey="id"
                filterDisplay="menu"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
                @filter="onFilter()"
                @page="onPage($event)"
                @sort="onSort($event)"
                @select-all-change="onSelectAllChange"
                @row-select="onRowSelect"
                @row-unselect="onRowUnselect"
            >
                <template #empty v-if="!loading">
                    <NoDataFound message="No Employees found." icon="Users" />
                </template>

                <!-- <Column
                    headerStyle="min-width: 36px"
                    selectionMode="multiple"
                ></Column> -->

                <Column
                    :show-add-button="false"
                    :show-filter-match-modes="false"
                    :show-filter-operator="false"
                    field="name"
                >
                    <template #body="{ data }">
                        <EmployeeIncompleteConfigurationModal
                            :employee="data"
                        />
                    </template>
                    <template #filter>
                        <h3 class="font-semibold">Incomplete Configuration</h3>

                        <div class="flex items-center mt-4">
                            <input
                                id="config_all"
                                type="checkbox"
                                v-model="
                                    allIncompleteConfiguration
                                "
                                value="All"
                                @change="
                                    handleSelectAllIncompleteConfiguration
                                "
                            />
                            <label for="config_all" class="ml-2">All</label>
                        </div>

                        <div
                            v-for="(
                                config, index
                            ) in incompleteConfigurationOptions"
                            class="flex items-center mt-2"
                        >
                            <input
                                :id="`config_${index}`"
                                type="checkbox"
                                v-model="
                                    incompleteConfiguration
                                "
                                :value="config.value"
                                @change="
                                    handleSelectIncompleteConfiguration
                                "
                            />
                            <label :for="`config_${index}`" class="ml-2">
                                {{ config.label }}
                            </label>
                        </div>
                    </template>
                    <template #filterclear>
                        <Button
                            class="border border-[#2196f3] px-2 py-1.5 text-[#2196f3]"
                            @click="
                                onClearIncompleteConfiguration
                            "
                        >
                            Clear
                        </Button>
                    </template>
                </Column>
                <Column :sortable="true" field="name" header="Name">
                    <template #body="{ data }">
                        <div class="flex items-center">
                            <!-- <Lucide
                                icon="AlertTriangle"
                                class="w-4 h-4 text-yellow-500"
                                v-if="
                                    data.employee_current_cost_history ===
                                        null ||
                                    data.employee_current_nonrefundable_history ===
                                        null ||
                                    data.employee_current_re_fee === null ||
                                    data.shift === null ||
                                    data.schedules_count === 0
                                "
                            /> -->
                            <router-link
                                    :to="{
                                        name: 'employeesDetails',
                                        params: {
                                            id: data.id
                                        }
                                    }"
                                >
                                <span class="font-medium cursor-pointer text-custom-tertiary">
                                    {{ data.name }}
                                </span>
                            </router-link>
                        </div>
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Employee Name"
                            type="text"
                        />
                    </template>
                </Column>
                <Column :sortable="true" field="position" header="Position">
                    <template #body="{ data }">
                        {{ data.position }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Position"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="client.legal_business_name"
                    header="Client Name"
                >
                    <template #body="{ data }">
                        {{
                            data.client.legal_business_name
                        }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Client"
                            type="text"
                        />
                    </template>
                </Column>
                <Column 
                    v-if="!AuthStore.role.includes('account manager')"
                    :sortable="true"
                    field="timezone_id" 
                    header="Timezone">
                    <template #body="{ data }">
                        {{
                            HelperStore.getTimezone(
                                parseInt(data.timezone_id)
                            ).name
                        }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Timezone"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="shift.shift.name"
                    header="Shift Schedule"
                >
                    <template #body="{ data }">
                        {{ data.shift?.shift?.name }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by shift"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    v-if="!AuthStore.role.includes('account manager')"
                    :sortable="true"
                    field="personal_email"
                    header="Personal Email"
                >
                    <template #body="{ data }">
                        {{ data.personal_email }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Email"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="primary_work_location"
                    header="Location"
                >
                    <template #body="{ data }">
                        <span v-if="data.primary_work_location === 'wfh'"> Work From Home</span>
                        <span v-else-if="data.primary_work_location === 'wfo'"> Work From Office</span>
                        <span v-else> Hybrid</span>
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Work Location"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="employeeStatusTypeGroup.name"
                    header="Status"
                >
                    <template #body="{ data }">
                        <div class="flex items-center justify-center">
                            <Badge
                                v-if="data.employee_status_type_group?.name === 'active'"
                                :title="capitalizeFirstLetter(data.employee_status_type_group?.name)"
                                variant="success"
                                icon="Circle"
                                background="transluscent"
                            />
                            <Badge
                                v-if="data.employee_status_type_group?.name === 'inactive'"
                                :title="capitalizeFirstLetter(data.employee_status_type_group?.name)"
                                variant="information"
                                icon="Circle"
                                background="transluscent"
                            />
                            <Badge
                                v-if="data.employee_status_type_group?.name === 'terminated'"
                                :title="capitalizeFirstLetter(data.employee_status_type_group?.name)"
                                variant="danger"
                                icon="Circle"
                                background="transluscent"
                            />
                        </div>
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Status"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :exportable="false"
                    style="width: 8rem; max-width: 8rem"
                >
                    <template #body="slotProps">
                        <div class="table-icon-wrapper">
                            <!-- View -->
                            <router-link
                                :to="{
                                    name: 'employeesDetails',
                                    params: {
                                        id: slotProps
                                            .data.id
                                    }
                                }"
                            >
                                <TableIcon
                                    action="view"
                                    :id="
                                        'view_' +
                                        slotProps.data
                                            .id
                                    "/>
                            </router-link>
                            <!-- View -->
                            <!-- Update Client Button -->
                            <TableIcon
                                v-if="
                                    Object.values(
                                        AuthStore.activatedPermissionsName
                                    ).includes(
                                        'can update employees'
                                    ) && !AuthStore.role.includes('account manager')
                                "
                                :id="
                                    'update_' +
                                    slotProps.data.id
                                "
                                @click="
                                        updateEmployee(
                                            slotProps.data
                                        )
                                    "
                                action="update"

                            />

                            <!-- Update -->
                            <!-- Delete -->
                            <TableIcon
                                v-if="
                                    Object.values(
                                        AuthStore.activatedPermissionsName
                                    ).includes(
                                        'can delete employees'
                                    )
                                "
                                :id="
                                    'delete_' +
                                    slotProps.data.id
                                "
                                @click="
                                    confirmDelete(
                                        slotProps.data
                                    )
                                "
                                action="delete"
                            />
                            <!-- Delete -->
                        </div>
                    </template>
                </Column>
                <template #paginatorstart>
                    <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
                </template>
                <template #paginatorend>
                    <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
                </template>
            </DataTable>
            <FormSlider
                header="Employee Form Details"
                :isOpen="isEmployeeFormSliderOut"
                @close="closeSlider()"
            >
                <FormEmployee
                    :id="employeeId"
                    :data="employeeApiData.data"
                    @close="closeSlider()"
                    @status="formEmployeeStatus($event)"
                ></FormEmployee>
            </FormSlider>

            <!-- <ConfirmModal
                :displayConfirmModal="showConfirmDialog"
                second-description="Employee has been successfully deleted."
                @closeConfirmModal="setConfirmDialog(false)"
                @proceedProcess="processDelete($event)"
            /> -->

            <ConfirmDialog
                :isOpen="showConfirmDialog"
                type="danger"
                header="Delete Employee"
                :description="messageAlerts('confirmDelete', 'employee')"
                confirmButton="Delete"
                @close="setConfirmDialog(false)"
                @confirm="processDelete($event)"
            />

            <BaseNotification
                refKey="successNotification"
            >
                {{ messageAlerts("successDelete", "employee") }}
            </BaseNotification>
        </div>
    </div>
</template>
