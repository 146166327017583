import { ref } from "vue";
import { InvoiceInterface } from "@/pages/interfaces/invoiceInterface";
import NoteInterface from "@/pages/interfaces/noteInterface";
import RolesInterface from "@/pages/interfaces/rolesInterface";
import dayjs from "dayjs";

import Dinero from "dinero.js";

import moment from "moment";

import { startCase } from "lodash";

export default function useHelper() {
    const currencyFormat = (value: number, format: string = "0,0.00") => {
        if (!value) return Dinero({ amount: 0 }).toFormat(format);

        value = Math.round(value * 100);

        return Dinero({ amount: value }).toFormat(format);
    };

    const formatName = (name: string) => {
        const parts = name.trim().split(" ");
        const firstName = parts[0];
        const lastNameInitial = parts.length > 1 ? parts[parts.length - 1][0] : "";

        return `${firstName} ${lastNameInitial}.`.trim();
    };

    const formatOrdinals = (n: number) => {
        const pr = new Intl.PluralRules("en-US", { type: "ordinal" });
        const suffixes = new Map([
            ["one", "st"],
            ["two", "nd"],
            ["few", "rd"],
            ["other", "th"],
        ]);
        const rule = pr.select(n);
        const suffix = suffixes.get(rule);

        return `${n}${suffix}`;
    };

    const getClientStatusColor = (statusName: string): string => {
        let color = '';
        switch (statusName) {
            case 'Active':
                color = 'green'; break;
            case 'Potential':
                color = 'blue'; break;
            case 'Inactive':
                color = 'red'; break;

            case 'Draft':
            default: color = 'slate'; break;
        }
    
        return color;
    };

    const getClientStatusVariant = (statusName: string): "success" | "information" | "danger" => {
        let color: "success" | "information" | "danger" = 'information';
        switch (statusName) {
            case 'Active':
                color = 'success'; break;
            case 'Potential':
                color = 'information'; break;
            case 'Inactive':
                color = 'danger'; break;

            case 'Draft':
            default: color = 'information'; break;
        }
    
        return color;
    };

    const getInvoiceStatusColor = (statusName: string): string => {
        let color = '';
        switch (statusName) {
            case 'Paid':
            case 'Approved':
                color = 'green'; break;

            case 'Sent':
            case 'For Review':
                color = 'blue'; break;
    
            case 'Overdue':
            case 'Cancelled':
                color = 'red'; break;
    
            case 'Draft':
            default: color = 'slate'; break;
        }
    
        return color;
    };

    const getInvoiceStatusVariant = (statusName: string): "success" | "tertiary" | "danger" | "secondary" | "information" => {
        let color: "success" | "tertiary" | "danger" | "secondary" | "information";
        switch (statusName) {
            case 'Paid':
            case 'Approved':
                color = 'success'; break;

            case 'Sent':
            case 'For Review':
                color = 'tertiary'; break;
    
            case 'Overdue':
            case 'Cancelled':
                color = 'danger'; break;
    
            case 'Draft':
            default: color = 'information'; break;
        }
    
        return color;
    };

    const getNoteLabel = (note: NoteInterface) => {
        if (note.note_type.name == 'Position') {
            return note.notable?.title;
        } else if (note.note_type.name == 'Position Candidate') {
            return formatName(note.notable?.candidate?.name);
        }
    };

    const getPositionStatusColor = (statusName: string): string => {
        let color = '';
        switch (statusName) {
            case 'sourcing':
            case 'pending interviews':
                color = 'blue'; break;

            case 'filled':
                color = 'green'; break;

            case 'pause':
                color = 'red'; break;

            case 'active':
            default: color = 'slate'; break;
        }
    
        return color;
    };

    const getPositionStatusVariant = (statusName: string): 'tertiary' | 'information' | 'danger' | 'success' => {
        let variant: 'tertiary' | 'information' | 'danger' | 'success' = 'success';
        switch (statusName) {
            case 'sourcing':
            case 'pending interviews':
                variant = 'tertiary';
                break;
            case 'filled':
                variant = 'information';
                break;
            case 'pause':
                variant = 'danger';
                break;
            default:
                variant = 'success';
                break;
        }
        return variant;
    };

    const getPositionInterviewStatusColor = (statusName: string): string => {
        let color = '';
        switch (statusName) {
            case 'Scheduled':
                color = 'blue'; break;

            case 'Completed':
                color = 'green'; break;

            case 'Cancelled':
                color = 'red'; break;

            case 'Pending':
            default: color = 'slate'; break;
        }
    
        return color;
    };

    const getPositionInterviewStatusVariant = (statusName: string): 'tertiary' | 'information' | 'danger' | 'success' | 'secondary' => {
        let variant: 'tertiary' | 'information' | 'danger' | 'success' | 'secondary' = 'success';
        switch (statusName) {
            case 'Scheduled':
                variant = 'tertiary';
                break;
            case 'Completed':
                variant = 'success';
                break;
            case 'Cancelled':
                variant = 'danger';
                break;
            default:
                variant = 'secondary';
                break;
        }
        return variant;
    }

    const getRoleName = (userRoles: RolesInterface[]): string => {
        const rolePriority = [
            'admin',
            'hr',
            'payroll',
            'client',
            'recruitment',
            'accounts receivable',
            'it',
            'team lead',
            'office admin',
            'sales',
            'accounting',
            'finance',
            'account manager',
            'client-user',
            'employee'
        ];

        let roleName = rolePriority.find(role => userRoles.map(userRole => userRole.name).includes(role)) ?? 'employee';

        if (roleName === 'hr') {
            roleName = 'HR';
        } else if (roleName === 'it') {
            roleName = 'IT';
        } else if (roleName === 'client-user') {
            roleName = 'Client User';
        } else {
            roleName = startCase(roleName);
        }

        return roleName;
    };

    const isInvoiceLocked = (invoice: InvoiceInterface) => {
        let locked = false;
    
        if (invoice.invoice_status?.name == 'Paid') {
            locked = true;
        } else if (invoice.generated_via_cli) {
            const generatedDate = moment(invoice.start_date).day(import.meta.env.VITE_APP_INVOICE_BILL_DAY ?? 17);
            const restrictedDate = generatedDate.add(1, 'month');
            if (moment().isSameOrAfter(restrictedDate)) {
                locked = true;
            }
        }
    
        return locked;
    };

    const generateTimeIntervals = (minutes: number) => {
        const intervals = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += minutes) {
                const period = hour < 12 ? 'AM' : 'PM';
                const formattedHour = ((hour % 12) || 12).toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                const formattedStringTime = `${formattedHour}:${formattedMinute} ${period}`;
                const formattedTime = moment(formattedStringTime, "hh:mm A").format("HH:mm:ss");
                intervals.push({ title: formattedStringTime, value: formattedTime});
            }
        }
        return intervals;
    };

    const timeFormatToString = (time: string) => {
        return moment(time, "HH:mm:ss").format("hh:mm A")
    }

    const dateFormatToString = (date: string) => {
        return dayjs(date).format('MMMM D, YYYY')
    }

    const formatPHPDate = (date: moment.MomentInput) => {
        return moment(date).format(
            "MMM D, YYYY • h:mm A (G[M]T Z)",
        );
    };

    const dateFormatWithoutYear = (date: string) => {
        return dayjs(date).format('MMMM D')
    }

    // For scrolling up specific element start
    const elementRef = ref<HTMLElement | null>(null);

    const scrollToElement = () => {
        if (elementRef.value) {
            elementRef.value.scrollIntoView({ behavior: 'smooth' });
        }
    };
    // For scrolling up specific element end


    return {
        currencyFormat,
        formatName,
        formatOrdinals,
        getClientStatusColor,
        getInvoiceStatusColor,
        getInvoiceStatusVariant,
        getNoteLabel,
        getPositionStatusColor,
        getPositionInterviewStatusColor,
        getPositionInterviewStatusVariant,
        getRoleName,
        isInvoiceLocked,
        generateTimeIntervals,
        timeFormatToString,
        dateFormatToString,
        formatPHPDate,
        dateFormatWithoutYear,
        elementRef,
        scrollToElement,
        getClientStatusVariant,
        getPositionStatusVariant,
    };
}
