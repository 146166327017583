<script lang="ts" setup>
    import {
        onMounted,
        provide,
        reactive,
        ref,
        toRefs,
        watch
    } from "vue";
    import "primevue/resources/themes/saga-blue/theme.css";
    import "primevue/resources/primevue.min.css";
    import "primeicons/primeicons.css";
    import { useClientsStore } from "@/stores/clientsStore";
    import { useEmployeesStore } from "@/stores/employeesStore";
    import { useFreskdeskStore } from "@/stores/freshdeskStore";
    import { useHelperStore } from "@/stores/helperStore";
    import { useAuthStore } from "@/stores/authStore";
    import { useOfficeStore } from "@/stores/officeStore";
    import TomSelect from "@/base-components/TomSelect";
    import {
        FormInput,
        FormLabel,
        FormSwitch
    } from "@/base-components/Form";
    import {
        NotificationElement, BaseNotification
    } from "@/base-components/Notification";
    import {
        decimal,
        email,
        helpers,
        maxLength,
        required,
        requiredIf,
        minValue,
        maxValue
    } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import moment from "moment/moment";
    import { useRoute } from "vue-router";
    import EmployeeInterface from "@/pages/interfaces/employeesInterface";
    import ClientInterface from "@/pages/interfaces/clientsInterface";
    import { useShiftStore } from "@/stores/shiftStore";
    import { useCurrencyStore } from "@/stores/currencyStore";
    import FormPreloader from "@/base-components/Loaders/FormPreloader.vue";
    import FormCardSeparator from "@/base-components/Card/FormCardSeparator.vue";
    import BaseButton from "@/base-components/Button/BaseButton.vue";
    import useHelper from "@/composables/helper";
    import { messageAlerts } from "@/utils/helper";

    const loading = ref(true);
    const processing = ref(false);
    const AuthStore = useAuthStore();
    const route = useRoute();
    const FreshdeskStore = useFreskdeskStore();
    const EmployeesStore = useEmployeesStore();
    const ClientsStore = useClientsStore();
    const HelperStore = useHelperStore();
    const ShiftStore = useShiftStore();
    const OfficeStore = useOfficeStore();
    const CurrencyStore = useCurrencyStore();

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const maxYear = currentYear + 1;

    const props = defineProps({
        data: {
            type: Object,
            default: {}
        },
        id: Number
    });

    //const emit = defineEmits(['openModal']);
    const { elementRef, scrollToElement } = useHelper();
    const errorNotification = ref<NotificationElement>();
    const errorNotificationToggle = () => {
        // Show notification
        errorNotification.value?.showToast();
    };

    provide(
        "bind[errorNotification]",
        (el: NotificationElement) => {
            errorNotification.value = el;
        }
    );

    const showErrorsNotification = () => {
        errorNotificationToggle();
    };

    const successNotification = ref<NotificationElement>();
    const successNotificationToggle = () => {
        successNotification.value?.showToast();
    };

    provide(
        "bind[successNotification]",
        (el: NotificationElement) => {
            successNotification.value = el;
        }
    );

    const showSuccessNotification = () => {
        successNotificationToggle();
    };

    const emit = defineEmits(["status", "close"]);

    const lazyParams = ref({});
    const dt = ref();

    const searchableCols = ref([
        "legal_business_name",
        "website",
        "trade_name",
        "address_line_1",
        "city",
        "local_business_identifier",
        "primary_company_number"
    ]);

    watch(
        () => props.id,
        async (newValue, oldValue) => {
            if (newValue !== 0) {
                getEmployeeDetails(newValue);
                EmployeesStore.updateState();
            } else {
                EmployeesStore.addState();
                resetForm();
            }
        }
    );

    const formData = reactive({
        id: "",
        user_id: "",
        client_id: "",
        third_party_employee_id: "",
        name: "",
        timezone_id: "",
        position: "",
        personal_phone: "",
        personal_email: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        zip: "",
        work_phone: "",
        work_email: "",
        biometric_id: "",
        client_email: "",
        office_id: "",
        secondary_work_location: "wfo",
        primary_work_location: "wfo",
        shift_id: "",
        notes: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        emergency_contact_relationship: "",
        internal_staff_flag: false,
        sss: "",
        pagibig: "",
        tin: "",
        phic: "",
        basic_salary: "",
        birthdate: "",
        start_date: moment(Date.now()).format("YYYY-MM-DD"),
        regularization_date: moment(Date.now())
            .add(6, "months")
            .format("YYYY-MM-DD"),
        orientation_date: moment(Date.now()).format("YYYY-MM-DD"),
        payment_method: "",
        bank_name: "",
        bank_type: "",
        bank_account_no: "",
        "201_complete": false,
        is_salaried: false,
        cost_effective_from: "",
        cost_effective_to: "",
        cost_amount: 0,
        cost_currency_id: ""
    });

    const backendValidationErrors = ref<any>({});
    const $externalResults = ref({});

    const rules = {
        client_id: { required: helpers.withMessage(messageAlerts("required", "client"), required) },
        third_party_employee_id: {
            required: helpers.withMessage(messageAlerts("required", "third party employee ID"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength","third party employee ID", "50"), maxLength(50))
        },
        name: {
            required: helpers.withMessage(messageAlerts("required", "name"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "name", "100"), maxLength(100))
        },
        timezone_id: { required: helpers.withMessage(messageAlerts("required", "timezone"), required) },
        position: {
            required: helpers.withMessage(messageAlerts("required", "position"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "position", "100"), maxLength(100))
        },
        work_phone: {
            required: helpers.withMessage(messageAlerts("required", "work phone"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "work phone", "50"), maxLength(50))
        },
        work_email: {
            required: helpers.withMessage(messageAlerts("required", "work email"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "work email", "100"), maxLength(100)),
            email: helpers.withMessage(messageAlerts("email", "work email"), email)
        },
        biometric_id: { required: helpers.withMessage(messageAlerts("required", "biometric_id"), required) },
        client_email: {
            maxLength: helpers.withMessage(messageAlerts("maxLength", "client email"), maxLength(100)),
            email: helpers.withMessage(messageAlerts("email", "client email"), email)
        },
        office_id: { required: helpers.withMessage(messageAlerts("required", "office"), required) },
        secondary_work_location: { required: helpers.withMessage(messageAlerts("required", "secondary work location"), required) },
        primary_work_location: { required: helpers.withMessage(messageAlerts("required", "primary work location"), required) },
        shift_id: { required: helpers.withMessage(messageAlerts("required", "shift"), required) },
        notes: {
            required: helpers.withMessage(messageAlerts("required", "notes"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "notes"), maxLength(100))
        },
        internal_staff_flag: {},
        regularization_date: {},
        start_date: { required: helpers.withMessage(messageAlerts("required","start date"), required) },
        orientation_date: { required: helpers.withMessage(messageAlerts("required", "orientation date"), required) },
        payment_method: {
            required: helpers.withMessage(messageAlerts("required", "payment method"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "payment method", "50"), maxLength(50))
        },
        bank_name: {},
        bank_type: {},
        bank_account_no: { maxLength: helpers.withMessage(messageAlerts("maxLength", ""), maxLength(12)) },
    };

    const senistiveDataRules = {
        personal_phone: {},
        personal_email: {
            required: helpers.withMessage(messageAlerts("required", "personal email"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "personal email", "100"), maxLength(100)),
            email: helpers.withMessage(messageAlerts("email", "personal email"), email)
        },
        birthdate: { required: helpers.withMessage(messageAlerts("required", "birthdate"), required) },
        emergency_contact_name: { maxLength: helpers.withMessage(messageAlerts("maxLength", "emergency contact"), maxLength(100)) },
        emergency_contact_phone: { maxLength: helpers.withMessage(messageAlerts("maxLength", "phone", "50"), maxLength(50)) },
        emergency_contact_relationship: {
            maxLength: helpers.withMessage(messageAlerts("maxLength", "relationship", "50"), maxLength(50))
        },
        sss: {
            // minValue: helpers.withMessage("The SSS must be greater than 0", minValue(1)),
            // maxValue: helpers.withMessage("The SSS must be less than 1000000", maxValue(1000000))
         },
        pagibig: {
            // minValue: helpers.withMessage("The PAGIBIG must be greater than 0", minValue(1)),
            // maxValue: helpers.withMessage("The PAGIBIG must be less than 1000000", maxValue(1000000))
        },
        tin: {
            // minValue: helpers.withMessage("The TIN must be greater than 0", minValue(1)),
            // maxValue: helpers.withMessage("The TIN must be less than 1000000", maxValue(1000000))
         },
        phic: {
            // minValue: helpers.withMessage("The PHIC must be greater than 0", minValue(1)),
            // maxValue: helpers.withMessage("The PHIC must be less than 1000000", maxValue(1000000))
        },
        payment_method: {
            required: helpers.withMessage(messageAlerts("required", "payment method"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "payment method", "50"), maxLength(50))
        },
        bank_name: {},
        bank_type: {},
        bank_account_no: { maxLength: helpers.withMessage(messageAlerts("maxLength", "bank account number", "12"), maxLength(12)) },
        basic_salary: {
            required: helpers.withMessage(messageAlerts("required", "basic salary"), required),
            decimal: helpers.withMessage(messageAlerts("number", "basic salary"), decimal),
            minValue: helpers.withMessage(messageAlerts("minValue", "basic salary", "1"), minValue(1)),
            maxValue: helpers.withMessage(messageAlerts("maxValue", "basic salary", "1,000,000"), maxValue(1000000))
        },
        address_line_1: {
            required: helpers.withMessage(messageAlerts("required", "address"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "address", "100"), maxLength(100))
        },
        address_line_2: { },
        city: {
            required: helpers.withMessage(messageAlerts("required", "city"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "city", "100"), maxLength(100))
        },
        zip: {
            required: helpers.withMessage(messageAlerts("required", "zip"), required),
            maxLength: helpers.withMessage(messageAlerts("maxLength", "zip", "100"), maxLength(100))
        },
        cost_effective_from: {
            required: helpers.withMessage(messageAlerts("required", "cost effective from"),
            requiredIf(
                () => Object.keys(props.data).length === 0
            ))
        },
        cost_effective_to: {},
        cost_amount: {
            required: helpers.withMessage(messageAlerts("required", "cost amount"),
            requiredIf(
                () => Object.keys(props.data).length === 0
            )),
        },
        cost_currency_id: {
            required: helpers.withMessage(messageAlerts("required", "cost currency"),
            requiredIf(
                () => Object.keys(props.data).length === 0
            ))
        }
    };

    onMounted(async () => {
        if (props.id !== 0) {
            await getEmployeeDetails(props.id);
        } else {
            await CurrencyStore.getCurrencies();
            EmployeesStore.addState();
            resetForm();
        }
        await getClientData();
        await getShiftSchedules();
        await getOffices();

        loading.value = false;
    });

    let clientList = ref<ClientInterface[]>([]);

    const getEmployeeDetails = async (
        id: number | undefined
    ) => {
        const employee = props.data;
        if (typeof employee !== "undefined") {
            formData.id = employee.id;
            formData.third_party_employee_id =
                employee.third_party_employee_id;
            formData.client_id = String(employee.client_id);
            formData.name = employee.name;
            formData.personal_phone = employee.personal_phone;
            formData.personal_email = employee.personal_email;
            formData.client_email = employee.client_email;
            formData.work_phone = employee.work_phone;
            formData.work_email = employee.work_email;
            formData.biometric_id = employee.sprout_id;
            formData.office_id = String(employee.office_id);
            formData.secondary_work_location = String(
                employee.secondary_work_location
            );
            formData.primary_work_location = String(
                employee.primary_work_location
            );
            formData.timezone_id = String(employee.timezone_id);
            formData.position = employee.position;
            formData.shift_id = String(employee.shift_id);
            formData.emergency_contact_name =
                employee.emergency_contact_name;
            formData.emergency_contact_phone =
                employee.emergency_contact_phone;
            formData.emergency_contact_relationship =
                employee.emergency_contact_relationship;
            formData.notes = employee.notes;
            formData.sss = employee.sss;
            formData.pagibig = employee.pagibig;
            formData.tin = employee.tin;
            formData.phic = employee.phic;
            formData.basic_salary = employee.basic_salary;
            formData.internal_staff_flag =
                employee.internal_staff_flag === "0"
                    ? false
                    : true;
            formData.regularization_date =
                employee.regularization_date !== null
                    ? formatPHPDate(
                          employee.regularization_date
                      )
                    : moment(Date.now())
                          .add(6, "months")
                          .format("YYYY-MM-DD");
            formData.start_date = employee.employee_active_dates
                ? formatPHPDate(
                      employee.employee_active_dates[0]
                          .start_date
                  )
                : moment(Date.now()).format("YYYY-MM-DD");
            formData.birthdate = employee.birthdate
                ? formatPHPDate(
                      employee.birthdate
                  )
                : "";
            formData.orientation_date =
                employee.employee_active_dates
                    ? formatPHPDate(
                          employee.employee_active_dates[0]
                              .orientation_date
                      )
                    : moment(Date.now()).format("YYYY-MM-DD");
            formData.address_line_1 = employee.address_line_1;
            formData.address_line_2 = employee.address_line_2;
            formData.city = employee.city;
            formData.zip = employee.zip;
            formData.payment_method = employee.payment_method;
            formData.bank_name = employee.bank_name;
            formData.bank_type = employee.bank_type;
            formData.bank_account_no = employee.bank_account_no;
            formData["201_complete"] = employee["201_complete"];
            formData.is_salaried = employee.is_salaried;

            EmployeesStore.mode = "Update";
            //clientList.push(employee.client);
        } else {
            EmployeesStore.mode = "Add";
            resetForm();
        }
    };

    const allowSensitiveData = () => {
        // @ts-ignore
        return (
            // @ts-ignore
            window.Laravel.permissions.filter(
                (item: { name: string }) =>
                    item.name ===
                    "can view employees sensitive data"
            ).length >= 1
        );
    };
    const validate = allowSensitiveData()
        ? useVuelidate(
              { ...rules, ...senistiveDataRules },
              toRefs(formData),
              {
                  $externalResults
              }
          )
        : useVuelidate(rules, toRefs(formData), {
              $externalResults
          });
    const submit = async () => {
        // console.log(formData.birthdate);
        // return false;
        validate.value.$reset();
        validate.value.$clearExternalResults();
        backendValidationErrors.value = {};
        validate.value.$touch();

        if(validate.value.$invalid) {
            backendValidationErrors.value = validate.value.$errors.map((error: any) => [error.$message]);
                scrollToElement();
                showErrorsNotification();
            return;
        }

        try {
            processing.value = true;
            const response =
                formData.id === ""
                    ? await EmployeesStore.save(
                          formData as unknown as EmployeeInterface
                      )
                    : await EmployeesStore.update(
                          formData as unknown as EmployeeInterface
                      );

            if (validate.value.$invalid) {
                backendValidationErrors.value = response.data;
                showErrorsNotification();
                processing.value = false;
                return;
            }

            if (response.status === "success") {
                if (formData.id !== "") {
                    if (route?.name === "employeesDetails") {
                        await EmployeesStore.getEmployeeDetails(
                            +route?.params?.id
                        );
                    } else {
                        await EmployeesStore.getAllEmployees();
                    }

                    showSuccessNotification();
                    resetForm();
                    closeModal();
                    validate.value.$reset();
                } else {
                    showSuccessNotification();
                    resetForm();
                    closeModal();
                    validate.value.$reset();
                    emit("status", true);
                }
            } else {
                backendValidationErrors.value = response.data;
                $externalResults.value = response.data;

                showErrorsNotification();
                if (response.data === "validation.unique") {
                    formData.personal_email = "";
                }
            }
        } catch (error: any) {
            console.error("error catch", error);
            backendValidationErrors.value = error.response.data;
            $externalResults.value = error.response.data;

            showErrorsNotification();
        } finally {
            processing.value = false;
        }
    };

    const resetForm = () => {
        formData.id = "";
        formData.user_id = "";
        formData.client_id = "";
        formData.third_party_employee_id = "";
        formData.name = "";
        formData.timezone_id = "";
        formData.position = "";
        formData.personal_phone = "";
        formData.personal_email = "";
        formData.work_phone = "";
        formData.work_email = "";
        formData.client_email = "";
        formData.office_id = "";
        formData.secondary_work_location = "wfo";
        formData.primary_work_location = "wfo";
        formData.shift_id = "";
        formData.notes = "";
        formData.emergency_contact_name = "";
        formData.emergency_contact_phone = "";
        formData.emergency_contact_relationship = "";
        formData.internal_staff_flag = false;
        formData.sss = "";
        formData.pagibig = "";
        formData.tin = "";
        formData.phic = "";
        formData.basic_salary = "";
        formData.birthdate = "";
        formData.start_date = moment(Date.now()).format(
            "YYYY-MM-DD"
        );
        formData.regularization_date = moment(Date.now())
            .add(6, "months")
            .format("YYYY-MM-DD");
        formData.orientation_date = moment(Date.now()).format(
            "YYYY-MM-DD"
        );
        formData.address_line_1 = "";
        formData.address_line_2 = "";
        formData.city = "";
        formData.zip = "";
        formData.payment_method = "Bank Account";
        // formData.bank_name =
        //     "UNION BANK OF THE PHILIPPINES(UnionHub)";
        formData.bank_name = "Security Bank";
        formData.bank_type = "Savings";
        formData.bank_account_no = "";
        formData["201_complete"] = false;
        formData.is_salaried = false;
        formData.cost_effective_from = "";
        formData.cost_effective_to = "";
        formData.cost_amount = 0;
        formData.cost_currency_id = "";
    };

    const isEmptyObject = (obj: any) => {
        return Object.keys(obj).length === 0;
    };

    const closeModal = () => {
        emit("close", true);
    };

    const getClientData = async () => {
        await ClientsStore.getClientsWithInvoiceConfig();
        clientList.value = ClientsStore.clients;
        return clientList;
    };

    const getShiftSchedules = async () => {
        await ShiftStore.getAllShifts({ dropdown: true });
    };

    const formatPHPDate = (date: moment.MomentInput) => {
        return moment(date).format("YYYY-MM-DD");
    };

    const enableSensitiveData = () => {
        return (
            EmployeesStore.employees.some(
                (employee) => employee.sensitiveData
            ) ||
            Object.values(
                AuthStore.activatedPermissionsName
            ).includes("can view employees sensitive data")
        );
    };

    const getOffices = async () => {
        await OfficeStore.getOffices();
    };
</script>
<template>
    <FormPreloader :loading="loading">
        <form v-if="!loading" @submit.prevent="submit" ref="elementRef">
            <div class="grid grid-cols-12 gap-5 mt-5 text-left items-left">
                <div class="col-span-12 lg:col-span-6">
                    <FormCardSeparator title="Employee Information" class="h-full">
                        <div>
                            <FormLabel for="name"
                                :important="true"
                                :error-validation="validate.name.$error">
                                Name
                            </FormLabel>
                            <FormInput
                                id="name"
                                v-model="formData.name"
                                :value="formData.name"
                                class="w-full"
                                maxLength="100"
                                name="name"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.name.$error
                                    ? validate.name.$errors[0].$message
                                    : backendValidationErrors?.name"
                            />
                        </div>

                        <div>
                            <FormLabel for="position"
                                :important="true"
                                :error-validation="validate.position.$error">
                                Position
                            </FormLabel>
                            <FormInput
                                id="position"
                                v-model="formData.position"
                                :value="formData.position"
                                class="w-full"
                                maxLength="100"
                                name="position"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.position.$error
                                    ? validate.position.$errors[0].$message
                                    : backendValidationErrors?.position"
                            />
                        </div>

                        <div>
                            <FormLabel
                                for="third_party_employee_id"
                                :important="true"
                                :error-validation="validate.third_party_employee_id.$error"
                            >
                                Third Party Employee ID
                            </FormLabel>
                            <FormInput
                                id="third_party_employee_id"
                                v-model="
                                        formData.third_party_employee_id
                                    "
                                :value="
                                        formData.third_party_employee_id
                                    "
                                class="w-full"
                                maxlength="50"
                                name="third_party_employee_id"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.third_party_employee_id.$error
                                    ? validate.third_party_employee_id.$errors[0].$message
                                    : backendValidationErrors?.third_party_employee_id"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="personal_phone">
                                Personal Phone
                            </FormLabel>
                            <FormInput
                                id="personal_phone"
                                v-model="
                                        formData.personal_phone
                                    "
                                :value="
                                        formData.personal_phone
                                    "
                                class="w-full"
                                maxLength="50"
                                name="personal_phone"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="backendValidationErrors?.personal_phone"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="personal_email"
                                :important="true"
                                :error-validation="validate.personal_email.$error">
                                Personal Email
                            </FormLabel>
                            <FormInput
                                id="personal_email"
                                v-model="
                                        formData.personal_email
                                    "
                                :value="
                                        formData.personal_email
                                    "
                                class="w-full"
                                name="personal_email"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.personal_email.$error
                                    ? validate.personal_email.$errors[0].$message
                                    : backendValidationErrors?.personal_email"
                            />
                        </div>

                        <div v-if="isEmptyObject(props.data)">
                            <FormLabel for="shift_id"
                                :important="true"
                                :error-validation="validate.shift_id.$error">
                                Shift Schedule
                            </FormLabel>
                            <TomSelect
                                id="shift_id"
                                v-model="formData.shift_id"
                                :value="formData.shift_id"
                                class="w-full p-0"
                                name="shift_id"
                                :with-validation="true"
                                :validated-error="validate.shift_id.$error
                                    ? validate.shift_id.$errors[0].$message
                                    : backendValidationErrors?.shift_id"
                            >
                                <option selected>Select Shift</option>
                                <option
                                    v-for="schedule in ShiftStore.allShifts"
                                    :value="schedule.id"
                                >
                                    {{ schedule.name }}
                                </option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel
                                for="birthdate"
                                :important="true"
                                :error-validation="validate.birthdate.$error"
                            >
                                Birth Date
                            </FormLabel>
                            <FormInput
                                id="birthdate"
                                v-model="formData.birthdate"
                                :value="formData.birthdate"
                                class="w-full"
                                name="birthdate"
                                type="date"
                                max="9999-12-31"
                                :with-validation="true"
                                :validated-error="validate.birthdate.$error
                                    ? validate.birthdate.$errors[0].$message
                                    : backendValidationErrors?.birthdate"
                            />
                        </div>

                        <div>
                            <FormLabel
                                for="regularization_date"
                                :error-validation="validate.regularization_date.$error"
                            >
                                Regularization Date
                            </FormLabel>
                            <FormInput
                                id="regularization_date"
                                v-model="formData.regularization_date"
                                :value="formData.regularization_date"
                                class="w-full"
                                name="regularization_date"
                                type="date"
                                max="9999-12-31"
                                :with-validation="true"
                                :validated-error="validate.regularization_date.$error
                                    ? validate.regularization_date.$errors[0].$message
                                    : backendValidationErrors?.regularization_date"
                            />
                        </div>

                        <div>
                            <FormLabel for="work_phone"
                                :important="true"
                                :error-validation="validate.work_phone.$error">
                                Work Phone
                            </FormLabel>
                            <FormInput
                                id="work_phone"
                                v-model="formData.work_phone"
                                :value="formData.work_phone"
                                class="w-full"
                                maxLength="50"
                                name="work_phone"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.work_phone.$error
                                    ? validate.work_phone.$errors[0].$message
                                    : backendValidationErrors?.work_phone"
                            />
                        </div>

                        <div>
                            <FormLabel for="work_email"
                                :important="true"
                                :error-validation="validate.work_email.$error">
                                Work Email
                            </FormLabel>
                            <FormInput
                                id="work_email"
                                v-model="formData.work_email"
                                :value="formData.work_email"
                                class="w-full"
                                name="work_email"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.work_email.$error
                                    ? validate.work_email.$errors[0].$message
                                    : backendValidationErrors?.work_email"
                            />
                        </div>

                        <div>
                            <FormLabel for="biometric_id"
                                :important="true"
                                :error-validation="validate.biometric_id.$error">
                                Biometric ID
                            </FormLabel>
                            <FormInput
                                id="biometric_id"
                                v-model="formData.biometric_id"
                                :value="formData.biometric_id"
                                class="w-full"
                                name="biometric_id"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.biometric_id.$error
                                    ? validate.biometric_id.$errors[0].$message
                                    : backendValidationErrors?.biometric_id"
                            />
                        </div>

                        <div>
                            <FormLabel for="location"
                                :important="true"
                                :error-validation="validate.office_id.$error">
                                Office
                            </FormLabel>
                            <TomSelect
                                id="office_id"
                                v-model="formData.office_id"
                                class="w-full p-0"
                                name="office_id"
                                :with-validation="true"
                                :validated-error="validate.office_id.$error
                                    ? validate.office_id.$errors[0].$message
                                    : backendValidationErrors?.office_id"
                            >
                                <option>Select Office</option>
                                <option
                                    v-for="office in OfficeStore
                                            .offices.data"
                                    :value="
                                            String(office.id)
                                        "
                                >
                                    {{ office.address }}
                                </option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel for="location"
                                :important="true"
                                :error-validation="validate.primary_work_location.$error">
                                Primary Work Location
                            </FormLabel>
                            <TomSelect
                                id="primary_work_location"
                                v-model="formData.primary_work_location"
                                class="w-full p-0"
                                name="primary_work_location"
                                :with-validation="true"
                                :validated-error="validate.primary_work_location.$error
                                    ? validate.primary_work_location.$errors[0].$message
                                    : backendValidationErrors?.primary_work_location"
                            >
                                <option value="wfh">Work from Home</option>
                                <option value="wfo">Work from Office</option>
                                <option value="hybrid">Hybrid</option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel
                                for="secondary_work_location"
                                :important="true"
                                :error-validation="validate.secondary_work_location.$error"
                            >
                                Secondary Work Location
                            </FormLabel>
                            <TomSelect
                                id="secondary_work_location"
                                v-model="
                                        formData.secondary_work_location
                                    "
                                class="w-full p-0"
                                name="secondary_work_location"
                                :with-validation="true"
                                :validated-error="validate.secondary_work_location.$error
                                    ? validate.secondary_work_location.$errors[0].$message
                                    : backendValidationErrors?.secondary_work_location"
                            >
                                <option value="wfh">Work from Home</option>
                                <option value="wfo">Work from Office</option>
                                <option value="hybrid">Hybrid</option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel for="timezone_id"
                                :important="true"
                                :error-validation="validate.timezone_id.$error">
                                Timezone
                            </FormLabel>
                            <TomSelect
                                id="timezone_id"
                                v-model="formData.timezone_id"
                                class="w-full p-0"
                                name="timezone_id"
                                :with-validation="true"
                                :validated-error="validate.timezone_id.$error
                                    ? validate.timezone_id.$errors[0].$message
                                    : backendValidationErrors?.timezone_id"
                            >
                                <option>Select Timezone</option>
                                <option
                                    v-for="timezone of HelperStore.timezones"
                                    :value="timezone.id"
                                >
                                    {{ timezone.name }}
                                </option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel for="start_date"
                                :important="true"
                                :error-validation="validate.start_date.$error">
                                Start Date
                            </FormLabel>
                            <FormInput
                                id="start_date"
                                v-model="formData.start_date"
                                :value="formData.start_date"
                                class="w-full"
                                name="start_date"
                                type="date"
                                max="9999-12-31"
                                :with-validation="true"
                                :validated-error="validate.start_date.$error
                                    ? validate.start_date.$errors[0].$message
                                    : backendValidationErrors?.start_date"
                            />
                        </div>

                        <div>
                            <FormLabel for="orientation_date"
                                :important="true"
                                :error-validation="validate.orientation_date.$error">
                                Orientation Date
                            </FormLabel>
                            <FormInput
                                id="orientation_date"
                                v-model="formData.orientation_date"
                                :value="formData.orientation_date"
                                class="w-full"
                                name="orientation_date"
                                type="date"
                                max="9999-12-31"
                                :with-validation="true"
                                :validated-error="validate.orientation_date.$error
                                    ? validate.orientation_date.$errors[0].$message
                                    : backendValidationErrors?.orientation_date"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="address_line_1"
                                :important="true"
                                :error-validation="validate.address_line_1.$error">
                                Address Line 1
                            </FormLabel>
                            <FormInput
                                id="address_line_1"
                                v-model="
                                        formData.address_line_1
                                    "
                                :value="
                                        formData.address_line_1
                                    "
                                class="w-full"
                                maxLength="100"
                                name="address_line_1"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.address_line_1.$error
                                    ? validate.address_line_1.$errors[0].$message
                                    : backendValidationErrors?.address_line_1"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="address_line_2"
                                :error-validation="validate.address_line_2.$error">
                                Address Line 2
                            </FormLabel>
                            <FormInput
                                id="address_line_2"
                                v-model="
                                        formData.address_line_2
                                    "
                                :value="
                                        formData.address_line_2
                                    "
                                class="w-full"
                                maxLength="100"
                                name="address_line_2"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.address_line_2.$error
                                    ? validate.address_line_2.$errors[0].$message
                                    : backendValidationErrors?.address_line_2"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="city"
                                :important="true"
                                :error-validation="validate.city.$error">
                                City
                            </FormLabel>
                            <FormInput
                                id="city"
                                v-model="formData.city"
                                :value="formData.city"
                                class="w-full"
                                maxLength="100"
                                name="city"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.city.$error
                                    ? validate.city.$errors[0].$message
                                    : backendValidationErrors?.city"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="zip"
                                :important="true"
                                :error-validation="validate.zip.$error">
                                Zip
                            </FormLabel>
                            <FormInput
                                id="zip"
                                v-model="formData.zip"
                                :value="formData.zip"
                                class="w-full"
                                maxLength="100"
                                name="zip"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.zip.$error
                                    ? validate.zip.$errors[0].$message
                                    : backendValidationErrors?.zip"
                            />
                        </div>
                    </FormCardSeparator>
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <FormCardSeparator
                        title="Client Information"
                        helper-text="Only clients with valid invoice config appear in the dropdown."
                    >
                        <div v-if="isEmptyObject(props.data)">
                            <FormLabel for="client_id"
                                :important="true"
                                :error-validation="validate.client_id.$error">
                                Client
                            </FormLabel>
                            <TomSelect
                                id="client_id"
                                v-model="formData.client_id"
                                :value="formData.client_id"
                                class="w-full"
                                name="client_id"
                                :options="{
                                    allowEmptyOption: true,
                                    placeholder:
                                        'Select Client'
                                }"
                                :with-validation="true"
                                :validated-error="validate.client_id.$error
                                    ? validate.client_id.$errors[0].$message
                                    : backendValidationErrors?.client_id"
                            >
                                <option value="">Select Client</option>
                                <option
                                    v-for="client of clientList"
                                    :key="client.id"
                                    :value="
                                        client.id?.toString()
                                    "
                                    :selected="
                                        parseInt(
                                            formData.client_id ??
                                                '0'
                                        ) === client.id
                                    "
                                >
                                    {{
                                        client.legal_business_name
                                    }}
                                </option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel for="client_email">
                                Client Email
                            </FormLabel>
                            <FormInput
                                id="client_email"
                                v-model="
                                    formData.client_email
                                "
                                :value="
                                    formData.client_email
                                "
                                class="w-full"
                                maxLength="100"
                                name="client_email"
                                placeholder=""
                                type="text"
                            />
                        </div>
                    </FormCardSeparator>

                    <FormCardSeparator title="Bank Information" class="mt-5">
                        <div v-if="enableSensitiveData()">
                            <FormLabel for="payment_method">
                                Payment Method
                            </FormLabel>
                            <TomSelect
                                id="payment_method"
                                v-model="
                                    formData.payment_method
                                "
                                :value="
                                    formData.payment_method
                                "
                                class="w-full p-0"
                                name="shift_id"
                                :with-validation="true"
                                :validated-error="validate.payment_method.$error
                                    ? validate.payment_method.$errors[0].$message
                                    : backendValidationErrors?.payment_method"
                            >
                                <option selected>Select Payment Method</option>
                                <option value="Bank Account">
                                    Bank Account
                                </option>
                                <option value="Cash">Cash</option>
                            </TomSelect>
                        </div>

                        <div
                            v-if="
                                formData.payment_method !==
                                'Cash'
                            "
                        >
                            <div v-if="enableSensitiveData()">
                                <FormLabel for="bank_name">Bank Name</FormLabel>
                                <FormInput
                                    id="bank_name"
                                    v-model="
                                        formData.bank_name
                                    "
                                    :value="
                                        formData.bank_name
                                    "
                                    class="w-full"
                                    name="bank_name"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.bank_name.$error
                                        ? validate.bank_name.$errors[0].$message
                                        : backendValidationErrors?.bank_name"
                                />
                            </div>

                            <div v-if="enableSensitiveData()">
                                <FormLabel for="bank_type">
                                    Bank Type
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="bank_type"
                                    v-model="
                                        formData.bank_type
                                    "
                                    :value="
                                        formData.bank_type
                                    "
                                    class="w-full"
                                    maxlength="50"
                                    name="bank_type"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.bank_type.$error
                                        ? validate.bank_type.$errors[0].$message
                                        : backendValidationErrors?.bank_type"
                                />
                            </div>

                            <div v-if="enableSensitiveData()">
                                <FormLabel for="bank_account_no"
                                    :error-validation="validate.bank_account_no.$error">
                                    Bank Account No.
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="bank_account_no"
                                    v-model="
                                        formData.bank_account_no
                                    "
                                    :value="
                                        formData.bank_account_no
                                    "
                                    class="w-full"
                                    maxlength="50"
                                    name="bank_account_no"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.bank_account_no.$error
                                        ? validate.bank_account_no.$errors[0].$message
                                        : backendValidationErrors?.bank_account_no"
                                />
                            </div>
                        </div>
                    </FormCardSeparator>

                    <FormCardSeparator title="Other Information" class="mt-5 ">
                        <div class="flex gap-2">
                            <div v-if="enableSensitiveData()" class="flex-1">
                                <FormLabel for="sss">
                                    SSS No.
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="sss"
                                    v-model="formData.sss"
                                    :value="formData.sss"
                                    class="w-full"
                                    maxlength="50"
                                    name="sss"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.sss.$error
                                        ? validate.sss.$errors[0].$message
                                        : backendValidationErrors?.sss"
                                />
                            </div>
                            <div v-if="enableSensitiveData()" class="flex-1">
                                <FormLabel for="pagibig">
                                    Pagibig No.
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="pagibig"
                                    v-model="formData.pagibig"
                                    :value="formData.pagibig"
                                    class="w-full"
                                    maxlength="50"
                                    name="pagibig"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.pagibig.$error
                                        ? validate.pagibig.$errors[0].$message
                                        : backendValidationErrors?.pagibig"
                                />
                            </div>
                        </div>
                        <div class="flex gap-2">
                            <div v-if="enableSensitiveData()" class="flex-1">
                                <FormLabel for="tin">
                                    Tax Indentification No.
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="tin"
                                    v-model="formData.tin"
                                    :value="formData.tin"
                                    class="w-full"
                                    maxlength="50"
                                    name="tin"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.tin.$error
                                        ? validate.tin.$errors[0].$message
                                        : backendValidationErrors?.tin"
                                />
                            </div>
                            <div v-if="enableSensitiveData()" class="flex-1">
                                <FormLabel for="phic">
                                    PhilHealth No.
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="phic"
                                    v-model="formData.phic"
                                    :value="formData.phic"
                                    class="w-full"
                                    maxlength="50"
                                    name="phic"
                                    placeholder=""
                                    type="text"
                                    :with-validation="true"
                                    :validated-error="validate.phic.$error
                                        ? validate.phic.$errors[0].$message
                                        : backendValidationErrors?.phic"
                                />
                            </div>
                        </div>
                        <div
                            v-if="
                                isEmptyObject(props.data) &&
                                enableSensitiveData()
                            "
                        >
                            <FormLabel for="basic_salary"
                                :important="true"
                                :error-validation="validate.basic_salary.$error">
                                Basic Salary
                            </FormLabel>
                            <FormInput
                                id="basic_salary"
                                v-model="formData.basic_salary"
                                :value="formData.basic_salary"
                                class="w-full"
                                maxlength="8"
                                name="basic_salary"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.basic_salary.$error
                                    ? validate.basic_salary.$errors[0].$message
                                    : backendValidationErrors?.basic_salary"
                            />
                        </div>

                        <div v-if="enableSensitiveData()">
                            <FormLabel for="notes"
                                :important="true"
                                :error-validation="validate.notes.$error">
                                Notes
                            </FormLabel>
                            <FormInput
                                id="notes"
                                v-model="formData.notes"
                                :value="formData.notes"
                                class="w-full"
                                maxlength="100"
                                name="notes"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.notes.$error
                                    ? validate.notes.$errors[0].$message
                                    : backendValidationErrors?.notes"
                            />
                        </div>

                        <div class="flex justify-between">
                            <div class="flex flex-col">
                                <FormLabel
                                    for="internal_staff_flag"
                                    class="block"
                                >
                                    Internal Staff Flag
                                </FormLabel>
                                <FormSwitch id="internal_staff_flag">
                                    <FormSwitch.Input
                                        v-model="formData.internal_staff_flag"
                                        type="checkbox"
                                        v-bind:checked="Boolean(formData.internal_staff_flag)"
                                    />
                                </FormSwitch>
                            </div>
                            <div class="flex flex-col">
                                <FormLabel for="internal_staff_flag">
                                    201 Complete
                                </FormLabel>
                                <FormSwitch id="201_complete">
                                    <FormSwitch.Input
                                        v-model="formData['201_complete']"
                                        type="checkbox"
                                        v-bind:checked="Boolean(formData['201_complete'])"
                                    />
                                </FormSwitch>
                            </div>
                            <div class="flex flex-col">
                                <FormLabel for="internal_staff_flag">
                                    Is Salaried?
                                </FormLabel>
                                <FormSwitch id="is_salaried">
                                    <FormSwitch.Input
                                        v-model="formData.is_salaried"
                                        type="checkbox"
                                        v-bind:checked="Boolean(formData.is_salaried)"
                                    />
                                </FormSwitch>
                            </div>
                        </div>
                    </FormCardSeparator>

                    <FormCardSeparator
                        title="Emergency Information"
                        class="mt-5"
                    >
                        <div v-if="enableSensitiveData()">
                            <FormLabel for="emergency_contact_name">
                                Emergency Contact Name
                                <!-- <span class="text-red-600">*</span> -->
                            </FormLabel>
                            <FormInput
                                id="emergency_contact_name"
                                v-model="formData.emergency_contact_name"
                                :value="formData.emergency_contact_name"
                                class="w-full"
                                name="emergency_contact_name"
                                placeholder=""
                                type="text"
                                :with-validation="true"
                                :validated-error="validate.emergency_contact_name.$error
                                    ? validate.emergency_contact_name.$errors[0].$message
                                    : backendValidationErrors?.emergency_contact_name"
                            />
                        </div>

                        <div class="flex gap-2">
                            <div v-if="enableSensitiveData()" class="flex-1">
                                <FormLabel for="emergency_contact_phone">
                                    Contact Number
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="emergency_contact_phone"
                                    v-model="formData.emergency_contact_phone"
                                    :value="formData.emergency_contact_phone"
                                    class="w-full"
                                    name="emergency_contact_phone"
                                    placeholder=""
                                    type="text"
                                    :with-validation="false"
                                    :validated-error="validate.emergency_contact_phone.$error
                                        ? validate.emergency_contact_phone.$errors[0].$message
                                        : backendValidationErrors?.emergency_contact_phone"
                                />
                            </div>
                            <div v-if="enableSensitiveData()" class="flex-1">
                                <FormLabel for="emergency_contact_relationship">
                                    Relationship
                                    <!-- <span class="text-red-600">*</span> -->
                                </FormLabel>
                                <FormInput
                                    id="emergency_contact_relationship"
                                    v-model="formData.emergency_contact_relationship"
                                    :value="formData.emergency_contact_relationship"
                                    class="w-full"
                                    maxlength="50"
                                    name="emergency_contact_relationship"
                                    placeholder=""
                                    type="text"
                                    :with-validation="false"
                                    :validated-error="validate.emergency_contact_relationship.$error
                                        ? validate.emergency_contact_relationship.$errors[0].$message
                                        : backendValidationErrors?.emergency_contact_relationship"
                                />
                            </div>
                        </div>
                    </FormCardSeparator>

                    <FormCardSeparator v-if="isEmptyObject(props.data)" title="Cost Information" class="mt-5">
                        <div>
                            <FormLabel for="cost_effective_from"
                                :important="true"
                                :error-validation="validate.cost_effective_from.$error">
                                Effective From
                            </FormLabel>
                            <FormInput
                                id="cost_effective_from"
                                v-model="formData.cost_effective_from"
                                :value="formData.cost_effective_from"
                                class="w-full"
                                name="cost_effective_from"
                                type="date"
                                max="9999-12-31"
                                :with-validation="true"
                                :validated-error="validate.cost_effective_from.$error
                                    ? validate.cost_effective_from.$errors[0].$message
                                    : backendValidationErrors?.cost_effective_from"
                            />
                        </div>

                        <div>
                            <FormLabel for="cost_effective_to">
                                Effective To
                            </FormLabel>
                            <FormInput
                                id="cost_effective_to"
                                v-model="formData.cost_effective_to"
                                :value="formData.cost_effective_to"
                                class="w-full"
                                name="cost_effective_to"
                                type="date"
                                max="9999-12-31"
                                :with-validation="true"
                                :validated-error="validate.cost_effective_to.$error
                                    ? validate.cost_effective_to.$errors[0].$message
                                    : backendValidationErrors?.cost_effective_to"
                            />
                        </div>

                        <div>
                            <FormLabel for="cost_currency_id"
                                :important="true"
                                :error-validation="validate.cost_currency_id.$error">
                                Currency
                            </FormLabel>
                            <TomSelect
                                id="cost_currency_id"
                                v-model="formData.cost_currency_id"
                                :value="formData.cost_currency_id"
                                class="w-full p-0"
                                name="cost_currency_id"
                                :with-validation="true"
                                :validated-error="validate.cost_currency_id.$error
                                    ? validate.cost_currency_id.$errors[0].$message
                                    : backendValidationErrors?.cost_currency_id"
                            >
                                <option selected>Select Currency</option>
                                <option
                                    v-for="currency in CurrencyStore
                                    .currencies?.data"
                                    :value="currency.id"
                                >
                                    {{ currency.code }}
                                </option>
                            </TomSelect>
                        </div>

                        <div>
                            <FormLabel for="cost_amount"
                                :important="true"
                                :error-validation="validate.cost_amount.$error">
                                Amount
                            </FormLabel>
                            <FormInput
                                id="cost_amount"
                                v-model.number="formData.cost_amount"
                                :value="formData.cost_amount"
                                class="w-full form-control"
                                name="cost_amount"
                                type="number"
                                :with-validation="true"
                                :validated-error="validate.cost_amount.$error
                                    ? validate.cost_amount.$errors[0].$message
                                    : backendValidationErrors?.cost_amount"
                            />
                        </div>
                    </FormCardSeparator>
                </div>
                <!-- END: Form Layout -->
            </div>
        </form>
        <div class="">
            <div class="form-button-wrapper">
                <BaseButton
                    id="btn_process"
                    class="min-w-[100px]"
                    variant="primary"
                    :label="`${EmployeesStore.getMode}`"
                    :processingLabel="
                        formData.id
                            ? 'Updating...'
                            : 'Adding...'
                    "
                    :isProcessing="processing"
                    @click="submit"
                    :icon="formData.id ? 'Save' : 'Plus'"
                    :disabled="processing"
                />
                <BaseButton
                    id="btn_cancel"
                    class="min-w-[100px]"
                    variant="danger"
                    icon="X"
                    label="Cancel"
                    @click="closeModal"
                    :disabled="processing"
                >
                </BaseButton>
            </div>
        </div>
    </FormPreloader>
    <!-- Success Notification -->
    <BaseNotification
        :options="{
            duration: 3000
        }"
        class="flex"
        refKey="successNotification"
        title="System Notification"
        type="success"
    >
            Employee Saved Successfully
    </BaseNotification>
    <!-- Success Notification -->
    <!-- BEGIN: Failed Notification Content -->
    <!-- v-if="
            backendValidationErrors &&
            Object.keys(backendValidationErrors).length
        " -->
    <BaseNotification
        :options="{
            duration: 3000
        }"
        class="flex"
        refKey="errorNotification"
        title="System Notification"
        type="error"
    >
            <ul class="pl-4 list-disc">
                <template v-for="errorBag in backendValidationErrors">
                    <li>
                        {{ errorBag[0] }}
                    </li>
                </template>
            </ul>
    </BaseNotification>
    <!-- BEGIN: Failed Notification Content -->
</template>
