    <script setup lang="ts">
    import { cva, type VariantProps } from "class-variance-authority";
    import { twMerge } from "tailwind-merge";
    import * as lucideIcons from "lucide-vue-next";
    import Tippy from "@/base-components/Tippy/Tippy.vue";
    import { omit, pick } from "lodash";

    export type Icon = keyof typeof lucideIcons;
    type ButtonProps = VariantProps<typeof button>;

    const props = withDefaults(
        defineProps<{
            variant?: ButtonProps["variant"];
            size?: ButtonProps["size"];
            kind?: ButtonProps["kind"];
            active?: ButtonProps["active"]; // For the tab variant to show the active state.
            type?: "button" | "submit" | "reset";
            disabled?: boolean;
            label?: string; // Label of the button
            icon?: Icon; // Icon name of the Lucide icon
            iconSize?: number; // Size of the icon
            isProcessing?: boolean; // To show the loading state of the button.
            processingLabel?: string; // Label of the button when it is in processing state.
            options?: {
                hideIcon?: boolean; // To hide the icon of the button
                showCounter?: boolean; // To show the counter of the button
                iconPosition?: "left" | "right"; // To change the position of the icon left and right.
            }
            tippyContent?: string; // To show the tippy content of the button.
        }>(),
        {
            variant: "primary",
            kind: "button",
            size: "sm",
            active: false,
            type: "button",
            label: "",
            icon: "Plus",
            iconSize: 5,
            showIcon: false,
            iconPosition: "left",
            isProcessing: false,
            tippyContent: "",
        }
    );

    const button = cva(
        [
            "flex gap-2 font-medium justify-center items-center transition-all ease-in duration-300",
            "disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200"
        ],
        /*tw*/
        {
            variants: {
                variant: {
                    primary:
                        "border-2 border-custom-primary bg-custom-primary text-white hover:bg-[#11213A] hover:text-white !outline-none",
                    secondary:
                        "border-2 border-custom-tertiary bg-custom-tertiary text-white hover:bg-cyan-700 hover:border-cyan-700 !outline-none",
                    outline:
                        "border-2 border-custom-primary bg-white text-custom-primary hover:bg-custom-primary hover:text-white !outline-none",
                    ghost: "text-custom-tertiary bg-white hover:bg-gray-100",
                    tab: "bg-background-light text-placeholder-text hover:bg-custom-tertiary hover:text-white outline-none",
                    danger: "border-2 border-custom-muted-red bg-custom-muted-red text-white hover:bg-[#BD2C2C] hover:border-[#BD2C2C] focus:border-[#BD2C2C] focus:ring-0 focus:outline-none",
                    success:
                        "border-2 border-custom-success bg-custom-success text-white hover:bg-green-700 hover:border-green-700 !outline-none",
                    information:
                        "border-2 border-custom-information bg-custom-information text-white hover:bg-yellow-600 hover:border-yellow-600 !outline-none",
                    disabled:
                        "bg-gray-200 text-gray-400 cursor-not-allowed border-2 border-bg-gray-200",
                    "dark-gray":
                        "border-2 border-[#69758A] bg-[#69758A] text-white hover:bg-[#444E5E] hover:border-[#444E5E] hover:text-white !outline-none",
                    "light-gray":
                        "border-2 border-[#B2BBCB] bg-[#B2BBCB] text-white hover:bg-[#7E8695] hover:text-white hover:border-[#7E8695] !outline-none",
                    "light-blue":
                        "border-2 border-custom-secondary bg-custom-secondary text-white hover:bg-[#305B73] hover:border-[#305B73] hover:text-white !outline-none",
                    "light":
                        "border-slate-300 border-2 text-slate-500 hover:bg-slate-100 bg-white hover:text-slate-400",
                },
                size: {
                    xs: "text-sm py-1 px-2",
                    sm: "text-sm py-2 px-2 h-min",
                    md: "text-sm py-2 px-3 h-min",
                    lg: "text-lg py-3 px-3 h-min",
                    xl: "text-xl py-4 px-4 h-min"
                },
                kind: {
                    button: "rounded-md",
                    icon: "rounded-full max-w-max",
                    padless:
                        "bg-transparent border-none hover:bg-transparent max-w-max"
                },
                active: {
                    true: "",
                    false: ""
                }
            },
            compoundVariants: [
                {
                    size: "xs",
                    kind: "icon",
                    class: "p-1 h-min"
                },
                {
                    size: "sm",
                    kind: "icon",
                    class: "p-2 h-min"
                },
                {
                    size: "md",
                    kind: "icon",
                    class: "p-3 h-min"
                },
                {
                    size: "lg",
                    kind: "icon",
                    class: "p-5 h-min"
                },
                {
                    variant: "tab",
                    active: true,
                    class: "h-full px-5 py-4 text-black bg-white rounded-none hover:bg-white hover:text-black"
                },
                {
                    variant: "tab",
                    active: false,
                    class: "h-full px-5 py-4 rounded-none"
                },
                {
                    kind: "padless",
                    variant: "ghost",
                    class: "py-1 px-1 h-min text-white hover:text-gray-400"
                },
                {
                    kind: "padless",
                    variant: "primary",
                    class: "py-1 px-1 h-min text-custom-primary hover:text-[#11213A]"
                },
                {
                    kind: "padless",
                    variant: "danger",
                    class: "py-1 px-1 h-min text-custom-danger hover:text-[#6C0C0C]"
                },
                {
                    kind: "padless",
                    variant: "secondary",
                    class: "py-1 px-1 h-min text-custom-tertiary hover:text-[#0E6679]"
                },
                {
                    kind: "padless",
                    variant: "success",
                    class: "py-1 px-1 h-min text-custom-success hover:text-[#3D7B17]"
                }
            ]
        }
    );
</script>

<template>
        <button
            :class="twMerge(button({ variant, size, kind, active }))"
            :type="type"
            :disabled="disabled"
        >
            <Tippy :content="`
                    <div class='flex flex-col'>
                        <span class='text-xs font-medium text-slate-400'>
                            Action:
                        </span>
                        <span class='font-bold'>
                            ${tippyContent}
                        </span>
                    </div>
                `"  
                class="flex items-center gap-2"
                :disabled="tippyContent === ''"
                :options="{
                    allowHTML: true,
                    theme: 'light',
                    offset: [0, 20]
                }">
                <slot
                    name="default"
                    v-if="!isProcessing">
                    <div
                        v-if="label !== 'Button' && !isProcessing"
                        class="flex items-center gap-2"
                        :class="{'!flex-row-reverse': options?.iconPosition === 'right'}"
                    >
                            <component
                                v-if="!options?.hideIcon"
                                :is="lucideIcons[props.icon]"
                                class="stroke-2 flex-shrink-0"
                                :class="`size-${props.iconSize}`"
                            />
                        
                        <span v-if="(kind === 'button' || kind === 'padless') && label !== ''">
                            {{ label }}
                        </span>
                    </div>
                </slot>
                <slot
                    v-else
                    name="processing">
                    <svg
                        aria-hidden="true"
                        class="h-5 w-5 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        fill="none"
                        viewBox="0 0 100 101"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                    {{ processingLabel }}
                </slot>
            </Tippy>
        </button>
</template>
