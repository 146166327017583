<script lang="ts" setup>
    import { defineProps, onMounted, ref, watch } from "vue";
    import { Slideover } from "@/base-components/Headless";
    import Lucide from "@/base-components/Lucide";
    import BaseButton from "@/base-components/Button/BaseButton.vue";

    type Size = "sm" | "md" | "lg" | "xl";

    const props = defineProps({
        isOpen: {
            type: Boolean,
            default: false
        },
        header: {
            type: String,
            default: "Form Details"
        },
        subHeader: {
            type: String,
            default: ""
        },
        size: {
            type: String as () => Size | undefined,
            default: "xl"
        }
    });

    const size = ref<Size | undefined>(
        props.size as Size | undefined
    );

    const emit = defineEmits(["close"]);

    const openSlider = ref(false);

    const closeSlider = (event: any) => {
        openSlider.value = false;
        emit("close", true);
    };

    watch(
        () => props.isOpen,
        (value) => {
            openSlider.value = value;
            size.value = props.size;
        }
    );

    watch(
        () => props.size,
        (value) => {
            size.value = value;
        }
    );

    onMounted(() => {
        size.value = props.size as Size | undefined;
    });
</script>

<template>
    <!-- @ts-ignore -->
    <Slideover
        :open="openSlider"
        :size="size"
        @close="
            () => {
                closeSlider(false);
            }
        "
    >
        <Slideover.Panel class="min-w-[370px]">
            <Slideover.Title
                class="flex flex-col justify-between border-none p-4 pb-0"
            >
                <div class="flex w-full items-center justify-between z-10 relative">
                    <h2 class="mr-auto text-xl font-bold md:text-2xl">
                        {{ header }}
                    </h2>
                    <BaseButton
                        variant="secondary"
                        kind="padless"
                        @click="
                            (event) => {
                                event.preventDefault();
                                closeSlider(false);
                            }
                        "
                    >
                        <Lucide class="size-6 block" icon="CircleChevronRight" />
                    </BaseButton>
                </div>
                <p class="mt-3 text-sm/relaxed">
                    {{ subHeader }}
                </p>
            </Slideover.Title>
            <Slideover.Description class="px-4 py-0">
                <slot></slot>
            </Slideover.Description>
        </Slideover.Panel>
    </Slideover>
</template>
