<script setup lang="ts">
    import { computed } from "vue";
    import Tippy from "@/base-components/Tippy/Tippy.vue";
    import * as lucideIcons from "lucide-vue-next";
    import { cva, type VariantProps } from "class-variance-authority";
    import { twMerge } from "tailwind-merge";

    const props = withDefaults(defineProps<{
        title?: string
        variant?: DivProps["variant"];
        background?: DivProps["background"];
        radius?: DivProps["radius"];
        size?: DivProps["size"];
        type?: DivProps["type"];
        icon?: keyof typeof lucideIcons;
    }>(), {
        variant: "primary",
        title: "Badge",
        background: "solid",
        radius: "default",
        type: "default",
        size: "sm",
    })

    type DivProps = VariantProps<typeof div>;

    const div = cva(
        [
            "flex items-center gap-1 text-white max-w-max font-bold cursor-pointer transition-all duration-300 hover:scale-110"
        ],
        {
            variants: {
                variant: {
                    primary: "bg-custom-primary fill-custom-primary",
                    secondary: "bg-custom-secondary",
                    tertiary: "bg-custom-tertiary",
                    success: "bg-custom-success",
                    information: "bg-custom-information",
                    danger: "bg-custom-muted-red",
                    warning: "bg-custom-warning",
                    light: "bg-gray-200 !text-slate-400",
                },
                background: {
                    solid: "bg-opacity-100",
                    transluscent: "bg-opacity-25"
                },
                radius: {
                    default: "rounded-[4px]",
                    full: "rounded-full"
                },
                size: {
                    sm: "px-2 py-1",
                    md: "px-3 py-2.5 text-base",
                    lg: "p-4 text-lg",
                },
                type: {
                    default: "",
                    icon: "!rounded-full"
                }
                
            },
            compoundVariants: [
                {
                    background: "transluscent",
                    type: "default",
                    variant: "primary",
                    class: "text-custom-primary"
                },
                {
                    background: "transluscent",
                    type: "default",
                    variant: "secondary",
                    class: "text-custom-secondary"
                },
                {
                    background: "transluscent",
                    type: "default",
                    variant: "tertiary",
                    class: "text-custom-tertiary"
                },
                {
                    background: "transluscent",
                    type: "default",
                    variant: "success",
                    class: "text-custom-success"
                },
                {
                    background: "transluscent",
                    type: "default",
                    variant: "information",
                    class: "text-custom-information"
                },
                {
                    background: "transluscent",
                    type: "default",
                    variant: "danger",
                    class: "text-custom-danger"
                },
                {
                    type: "icon",
                    size: "sm",
                    class: "!p-1"
                },
                {
                    type: "icon",
                    size: "md",
                    class: "!p-3"
                }
            ]
        }
    );

    const iconSize = computed(() => {
        if (props.size === 'sm') {
            return 'h-4 w-4 stroke-2.5';
        } else if (props.size === 'md') {
            return 'h-6 w-6 stroke-2.5';
        } else if (props.size === 'lg') {
            return 'h-8 w-8 stroke-2.5';
        }
    });
</script>

<template>

    <div
        v-if="type === 'default'"
        :class="twMerge(div({variant, background, radius, size, type}))"
    >
            <component
                v-if="icon?.length"
                :is="lucideIcons[icon]"
                :class="[{ '!h-1.5 !w-1.5 fill-white': icon === 'Circle' },
                    { '!fill-custom-primary': icon === 'Circle' && variant === 'primary' && background === 'transluscent' },
                    { '!fill-custom-primary': icon === 'Circle' && variant === 'primary' && background === 'transluscent' },
                    { '!fill-custom-secondary': icon === 'Circle' && variant === 'secondary' && background === 'transluscent'},
                    { '!fill-custom-tertiary': icon === 'Circle' && variant === 'tertiary' && background === 'transluscent'},
                    { '!fill-custom-success': icon === 'Circle' && variant === 'success' && background === 'transluscent'},
                    { '!fill-custom-information': icon === 'Circle' && variant === 'information' && background === 'transluscent'},
                    { '!fill-custom-danger': icon === 'Circle' && variant === 'danger' && background === 'transluscent'},
                    iconSize
                ]"
            />
            <span v-if="type === 'default'"
                class="whitespace-nowrap">
                {{ title }}
            </span>
    </div>
    <Tippy v-else as="div" :content="title"
        :class="twMerge(div({variant, background, radius, size, type}))"
    >
            <component
                v-if="icon?.length"
                :is="lucideIcons[icon]"
                class="h-4 w-4 stroke-2.5" />
    </Tippy>
    <!-- <template v-else>
        <Tippy
            :content="title"
            as="div"
            class="flex cursor-pointer items-center gap-1 rounded-full p-1 text-xs font-bold
                text-white hover:opacity-80"
            :style="{ backgroundColor: `${color}` }"
        >
            <component
                v-if="icon?.length"
                :is="lucideIcons[icon]"
                class="h-4 w-4 stroke-2.5" />
        </Tippy>
    </template> -->
</template>
