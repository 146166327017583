<script lang="ts" setup>
    import { provide, reactive, ref, toRefs } from "vue";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { useEmployeesStore } from "../../../stores/employeesStore";
    import Button from "@/base-components/Button";
    import BaseButton from "@/base-components/Button/BaseButton.vue";
    import Lucide from "@/base-components/Lucide";
    import Modal from "@/base-components/Dialog/Modal.vue";
    import {
        NotificationElement, BaseNotification
    } from "@/base-components/Notification";
    import SubmitButton from "@/base-components/Button/SubmitButton.vue";
    import Tippy from "@/base-components/Tippy/Tippy.vue";

    const emit = defineEmits(["fetchEmployees"]);

    const uploading = ref(false);
    const dialog = ref(false);
    const EmployeeStore = useEmployeesStore();
    const backendValidationErrors = ref({});
    const errorNotification = ref<NotificationElement>();
    const successNotification = ref<NotificationElement>();
    //@ts-ignore
    const $externalResults = ref<Record<string, any> | []>({});
    const inputFile = ref<HTMLInputElement>();

    const uploadFile = (e: Event) => {
        const target = e.target as HTMLInputElement;

        if (!target.files) {
            return;
        }

        form.attachment = target.files[0];
        import_error.value = "";
        import_errors.value = [];
    };

    const form = reactive({
        attachment: null as File | null
    });

    const rules = {
        attachment: {
            required
        }
    };
    //@ts-ignore
    //prettier-ignore
    const validate = useVuelidate(rules, toRefs(form), {$externalResults});

    const open = () => {
        dialog.value = true;
    };

    const close = () => {
        dialog.value = false;
        $externalResults.value = {};
        import_error.value = "";
        import_errors.value = [];
    };

    const download = async () => {
        await EmployeeStore.downloadImportTemplate();
    };

    const errorNotificationToggle = () => {
        // Show notification
        errorNotification.value?.showToast();
    };
    provide(
        "bind[errorNotification]",
        (el: NotificationElement) => {
            errorNotification.value = el;
        }
    );
    const showErrorsNotification = () => {
        errorNotificationToggle();
    };

    const successNotificationToggle = () => {
        successNotification.value?.showToast();
    };
    provide(
        "bind[successNotification]",
        (el: NotificationElement) => {
            successNotification.value = el;
        }
    );
    const showSuccessNotification = () => {
        successNotificationToggle();
    };

    const import_errors = ref<string[]>([]);
    const import_error = ref<string | null>(null);

    const onClickUpload = async () => {
        uploading.value = true;
        validate.value.$reset();
        validate.value.$clearExternalResults();
        validate.value.$touch();

        if (validate.value.$invalid) {
            backendValidationErrors.value = {
                message: ["Incomplete or Missing required data"]
            };

            showErrorsNotification();
            uploading.value = false;
            return;
        }

        if (!validate.value.$invalid) {
            try {
                const fd = new FormData();
                fd.append(
                    "attachment",
                    form.attachment as File
                );
                await EmployeeStore.importEmployees(fd).then(
                    async (response: any) => {
                        if (response.status === "success") {
                            close();
                            showSuccessNotification();
                            emit("fetchEmployees");
                        } else {
                            // @ts-ignore
                            inputFile.value.value = null;
                            if (response.error?.errors.file) {
                                import_errors.value =
                                    response.error.errors.file;
                            } else if (response.error?.errors) {
                                // if (response.error?.errors['attachment'][0]) {
                                //     import_errors.value = response.error?.errors['attachment'];
                                // }
                                if (
                                    response.error.errors.errors
                                ) {
                                    import_errors.value =
                                        response.error.errors.errors;
                                } else {
                                    import_errors.value =
                                        response.error?.errors[
                                            "attachment"
                                        ];
                                }
                            } else if (response.error.error) {
                                import_error.value =
                                    response.error.error;
                            }
                            uploading.value = false;
                        }
                    }
                );
            } catch (error: any) {
                // @ts-ignore
                inputFile.value.value = null;
                form.attachment = null;
                $externalResults.value =
                    error?.response?.data?.errors;
                validate.value.$reset();
            } finally {
                uploading.value = false;
            }
        }
    };
</script>

<template>
    <Modal :open="dialog" size="xl">
        <template #trigger>
            <BaseButton
                    variant="success"
                    kind="icon"
                    @click="open"
                    icon="Import"
                    tippy-content="Import Employee"
                >
            </BaseButton>
        </template>

        <template #content>
            <div
                class="mb-4 flex rounded-lg bg-blue-100 p-4 text-sm text-blue-800
                    dark:bg-gray-800 dark:text-blue-400"
                role="alert"
            >
                <svg
                    class="me-3 mt-[2px] inline h-4 w-4 flex-shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                    />
                </svg>
                <span class="sr-only">Info</span>
                <div class="ml-2">
                    <span class="font-medium">
                        Please be informed that certain fields are not updated
                        through the import process. These fields include:
                    </span>
                    <ul class="mt-2 list-inside list-disc">
                        <li>Employee Status</li>
                        <li>Employee Cost</li>
                        <li>RE Fees</li>
                        <li>Nonrefundable Amount</li>
                        <li>
                            Import process does not accomodate terminations and
                            suspension at the moment
                        </li>
                    </ul>
                    <div class="mb-2 mt-2">
                        These values are set only during the creation process
                        and are not modified by imports. To ensure accurate
                        data, kindly review and update these fields manually
                        when necessary.
                    </div>
                    <span class="mt-2 font-medium">
                        The following fields should follow the validations
                        below:
                    </span>
                    <ul class="mt-2 list-inside list-disc">
                        <li>Phone numbers must be numeric</li>
                        <li>Amounts such as Base Pay must be numeric</li>
                        <li>
                            Shift should be set as
                            <span class="font-medium">
                                Mid Shift, Day Shift, Night Shift
                            </span>
                        </li>
                        <li>
                            Timezone must be in
                            <span class="font-medium">Asia/Manila</span>
                            format.
                        </li>
                        <!-- <li>Dates must be in <span class="font-medium">mm/dd/YYYY</span> format (e.g. 09/18/2024).</li> -->
                        <li>
                            Primary location, secondary location and work
                            location must be
                            <span class="font-medium">wfo, wfh or hybrid</span>
                            .
                        </li>
                    </ul>
                    <div class="mt-2">
                        Thank you for your attention to this matter.
                    </div>
                </div>
            </div>

            <div
                class="flex items-center border-b border-gray-400/50
                    dark:border-darkmode-400"
            >
                <h2 class="mb-4 mr-auto text-base font-medium">Select File</h2>
            </div>

            <div
                v-if="import_error"
                class="mt-4 rounded bg-red-200 px-4 text-red-600"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li>
                        {{ import_error }}
                    </li>
                </ul>
            </div>

            <div
                v-if="import_errors.length > 0"
                class="mt-4 rounded bg-red-200 px-4 text-red-600"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li
                        v-for="(
                            error, index
                        ) in import_errors"
                        :key="index"
                    >
                        {{ error }}
                    </li>
                </ul>
            </div>

            <!-- <div
                v-if="$externalResults?.length > 0"
                class="mt-4 px-4 bg-red-200 text-red-600 rounded"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li v-for="error in $externalResults">
                        {{ error[0] }}
                    </li>
                </ul>
            </div> -->

            <div class="mt-4">
                <p>
                    <input
                        ref="inputFile"
                        id="uploadFileBtn"
                        class="btn mr-2 shadow-md"
                        type="file"
                        @change="uploadFile"
                    />
                </p>
                <template v-if="validate.attachment.$error">
                    <div
                        v-for="(error, index) in validate
                            .attachment.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </template>

        <template #action>
            <div class="flex flex-col sm:flex-row gap-2 justify-between">
                <div>
                    <Button
                        class="w-full sm:w-auto"
                        type="button"
                        variant="outline-secondary"
                        @click="download"
                    >
                        Download Template
                    </Button>
                </div>

                <div class="flex flex-col sm:flex-row gap-2 justify-end">
                    <Button
                        class="w-full sm:w-24"
                        type="button"
                        variant="outline-secondary"
                        @click="close"
                    >
                        Close
                    </Button>

                    <SubmitButton
                        id="btn_process"
                        class="w-full sm:w-32"
                        variant="primary"
                        processing-label="Importing..."
                        :is-processing="uploading"
                        @click="onClickUpload"
                    >
                        Upload
                    </SubmitButton>
                </div>
            </div>
        </template>
    </Modal>

    <BaseNotification
        refKey="successNotification"
    >
            Employees has been imported successfully.
    </BaseNotification>

    <BaseNotification
        type="error"
        refKey="errorNotification"
    >
        <ul class="list-disc list-inside">
            <template v-for="errorBag in backendValidationErrors">
                <li>
                    {{ errorBag[0] }}
                </li>
            </template>
        </ul>
    </BaseNotification>
</template>

<style scoped></style>
