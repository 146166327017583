import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { parseColor } from "tailwindcss/lib/util/color";
import moment from "moment";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const cutText = (text: string, length: number) => {
    if (text.split(" ").length > 1) {
        const string = text.substring(0, length);
        const splitText = string.split(" ");
        splitText.pop();
        return splitText.join(" ") + "...";
    } else {
        return text;
    }
};

const formatDate = (
    date: string,
    format: string = "MMM DD, YYYY h:mm A",
    timezone: string | null = null
) => {
    if (timezone) {
        // return dayjs(date).tz(timezone).format(format);
        return dayjs.tz(date, "Asia/Manila").tz(timezone).format(format);
    }

    return dayjs(date).format(format);
};

const formatTime = (
    time: string,
    format: string,
    timezone: string | null = null
) => {
    const currentDate = new Date().toJSON().slice(0, 10);
    return formatDate(currentDate + " " + time, format, timezone);
};

const timeIntervalsWithTz = (
    intervalMins: number,
    timezone: string | null = null
) => {
    const intervals = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += intervalMins) {
            const formattedStringTime = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}:00`;
            const formattedTime = formatTime(formattedStringTime, "h:mm A");
            let phTime = formattedStringTime;
            if (timezone) {
                const currentDate = new Date().toJSON().slice(0, 10);
                phTime = dayjs
                    .tz(currentDate + " " + formattedStringTime, timezone)
                    .tz("Asia/Manila")
                    .format("HH:mm:ss");
            }
            intervals.push({
                title: formattedTime,
                value: phTime
            });
        }
    }

    return intervals;
};

// const capitalizeFirstLetter = (string: string) => {
//     if (string) {
//         return string.charAt(0).toUpperCase() + string.slice(1);
//     } else {
//         return "";
//     }
// };

const onlyNumber = (string: string) => {
    if (string) {
        return string.replace(/\D/g, "");
    } else {
        return "";
    }
};

const formatCurrency = (number: number) => {
    if (number) {
        const formattedNumber = number.toString().replace(/\D/g, "");
        const rest = formattedNumber.length % 3;
        let currency = formattedNumber.substr(0, rest);
        const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
        let separator;

        if (thousand) {
            separator = rest ? "." : "";
            currency += separator + thousand.join(".");
        }

        return currency;
    } else {
        return "";
    }
};

const timeAgo = (time: string) => {
    const date = new Date(
        (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
        return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
        (dayDiff === 0 &&
            ((diff < 60 && "just now") ||
                (diff < 120 && "1 minute ago") ||
                (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
                (diff < 7200 && "1 hour ago") ||
                (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
        (dayDiff === 1 && "Yesterday") ||
        (dayDiff < 7 && dayDiff + " days ago") ||
        (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
};

const diffTimeByNow = (time: string) => {
    const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
        (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
        minutes += 1;
    }

    return {
        days: days.toString().length < 2 ? "0" + days : days,
        hours: hours.toString().length < 2 ? "0" + hours : hours,
        minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
        seconds: seconds.toString().length < 2 ? "0" + seconds : seconds
    };
};

const isset = (obj: object | string) => {
    if (obj !== null && obj !== undefined) {
        if (typeof obj === "object" || Array.isArray(obj)) {
            return Object.keys(obj).length;
        } else {
            return obj.toString().length;
        }
    }

    return false;
};

const toRaw = (obj: object) => {
    return JSON.parse(JSON.stringify(obj));
};

const randomNumbers = (from: number, to: number, length: number) => {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
        numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
};

const toRGB = (value: string) => {
    return parseColor(value).color.join(" ");
};

const stringToHTML = (arg: string) => {
    const parser = new DOMParser(),
        DOM = parser.parseFromString(arg, "text/html");
    return DOM.body.childNodes[0] as HTMLElement;
};

const slideUp = (
    el: HTMLElement,
    duration = 300,
    callback = (el: HTMLElement) => {}
) => {
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.height = el.offsetHeight + "px";
    el.offsetHeight;
    el.style.overflow = "hidden";
    el.style.height = "0";
    el.style.paddingTop = "0";
    el.style.paddingBottom = "0";
    el.style.marginTop = "0";
    el.style.marginBottom = "0";
    window.setTimeout(() => {
        el.style.display = "none";
        el.style.removeProperty("height");
        el.style.removeProperty("padding-top");
        el.style.removeProperty("padding-bottom");
        el.style.removeProperty("margin-top");
        el.style.removeProperty("margin-bottom");
        el.style.removeProperty("overflow");
        el.style.removeProperty("transition-duration");
        el.style.removeProperty("transition-property");
        callback(el);
    }, duration);
};

const slideDown = (
    el: HTMLElement,
    duration = 300,
    callback = (el: HTMLElement) => {}
) => {
    el.style.removeProperty("display");
    let display = window.getComputedStyle(el).display;
    if (display === "none") display = "block";
    el.style.display = display;
    let height = el.offsetHeight;
    el.style.overflow = "hidden";
    el.style.height = "0";
    el.style.paddingTop = "0";
    el.style.paddingBottom = "0";
    el.style.marginTop = "0";
    el.style.marginBottom = "0";
    el.offsetHeight;
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.height = height + "px";
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    window.setTimeout(() => {
        el.style.removeProperty("height");
        el.style.removeProperty("overflow");
        el.style.removeProperty("transition-duration");
        el.style.removeProperty("transition-property");
        callback(el);
    }, duration);
};

const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const capitalizeFirstLetters = (string: string) => {
    const avoidWords = ["a", "an", "the", "and", "or", "but", "if", "then", "else", "when", "where", "why", "how", "all", "any", "some", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];
    if (!string) return "";
    return string
        .split(" ")
        .map(word => avoidWords.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

const unformatString = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};

type MessageFor =
    | "required"
    | "added"
    | "updated"
    | "errorDelete"
    | "successDelete"
    | "confirmDelete"
    | "notFound"
    | "after"
    | "before"
    | "minValue"
    | "maxValue"
    | "minLength"
    | "maxLength"
    | "email"
    | "number"
    | "url"
    | "regenerated"
    | "sent"
    | "generated"
    | "maxFileSize"
    | "fileType"
    | "copyClipboard"
    | "somethingWentWrong"
    | "exported"
    | "taken"
    | "removed"
    | "assigned"
    | "confirmRemove"
    | "setAsDefault"
    | "renamed"
    | "cloned"
    | "saved"
    | "created"
    | "confirmRemove";

const messageAlerts = (
    messageFor: MessageFor,
    text: string,
    secondText?: string
) => {
    let message = "";
    switch (messageFor) {
        case "required":
            message = `The ${text.toLowerCase()} is required.`;
            break;
        case "added":
            message = `${capitalizeFirstLetter(text)} has been successfully added.`;
            break;
        case "regenerated":
            message = `${capitalizeFirstLetter(text)} has been successfully regenerated.`;
            break;
        case "generated":
            message = `${capitalizeFirstLetter(text)} has been successfully generated.`;
            break;
        case "sent":
            message = `${capitalizeFirstLetter(text)} has been successfully sent.`;
            break;
        case "updated":
            message = `${capitalizeFirstLetter(text)} has been successfully updated.`;
            break;
        case "removed":
            message = `${capitalizeFirstLetter(text)} has been successfully removed.`;
            break;
        case "assigned":
            message = `${capitalizeFirstLetter(text)} has been successfully assigned.`;
            break;
        case "errorDelete":
            message = `An error occurred while deleting ${text.toLowerCase()}. If the issue persists, please contact support.`;
            break;
        case "successDelete":
            message = `${capitalizeFirstLetter(text)} has been successfully deleted.`;
            break;
        case "confirmDelete":
            message = `Are you sure you want to delete this ${text.toLowerCase()}? This action is irreversible, and all associated data will be permanently removed from our servers.`;
            break;
        case "confirmRemove":
            message = `Are you sure you want to remove this ${text.toLowerCase()}?`;
            break;
        case "notFound":
            message = `No ${text.toLowerCase()} found.`;
            break;
        case "after":
            message = `The ${text.toLowerCase()} must not occur before the ${secondText?.toLowerCase()}.`;
            break;
        case "minValue":
            message = `The ${text.toLowerCase()} must be at least ${secondText?.toLowerCase()}.`;
            break;
        case "maxValue":
            message = `The ${text.toLowerCase()} must not exceed ${secondText?.toLowerCase()}.`;
            break;
        case "minLength":
            message = `The ${text.toLowerCase()} must be at least ${secondText?.toLowerCase()} characters long.`;
            break;
        case "maxLength":
            message = `The ${text.toLowerCase()} must not exceed ${secondText?.toLowerCase()} characters.`;
            break;
        case "email":
            message = `The ${text.toLowerCase()} must be a valid email address.`;
            break;
        case "number":
            message = `The ${text.toLowerCase()} must be a valid number.`;
            break;
        case "url":
            message = `The ${text.toLowerCase()} must be a valid URL.`;
            break;
        case "maxFileSize":
            message = `The ${text.toLowerCase()} must not exceed ${secondText?.toLowerCase()} MB only.`;
            break;
        case "fileType":
            message = `The ${text.toLowerCase()} must be of type: ${secondText?.toLowerCase()}.`;
            break;
        case "copyClipboard":
            message = `Link has been successfully copied to clipboard.`;
            break;
        case "somethingWentWrong":
            message = `Something went wrong while ${secondText?.toLowerCase()} ${text.toLowerCase()}. Please try again. If the issue persists, please contact support.`;
            break;
        case "exported":
            message = `${capitalizeFirstLetter(text)} has been successfully exported.`;
            break;
        case "taken":
            message = `The ${text.toLowerCase()} has already been taken.`;
            break;
        case "setAsDefault":
            message = `${capitalizeFirstLetter(text)} has been successfully set as default.`;
            break;
        case "renamed":
            message = `${capitalizeFirstLetter(text)} has been successfully renamed.`;
            break;
        case "cloned":
            message = `${capitalizeFirstLetter(text)} has been successfully cloned.`;
            break
        case "saved":
            message = `${capitalizeFirstLetter(text)} has been successfully saved.`;
            break;
        case "created":
            message = `${capitalizeFirstLetter(text)} has been successfully created.`;
            break;
        default:
            message = text;
            break;
    }
    return message;
};

const formatPHPDate = (date: moment.MomentInput) => {
    return moment(date).format("MMM D, YYYY • h:mm A (G[M]T Z)");
};

type DateInput = string | Date | dayjs.Dayjs;
interface DateFormatOptions {
    separator?: string;
    yearFormat?: string;
    monthFormat?: string;
    dayFormat?: string;
    ignoreCurrentYear?: boolean;
}

const formatDateRange = (
    startDate: DateInput,
    endDate: DateInput | null = null,
    {
        separator = "-",
        yearFormat = "YYYY",
        monthFormat = "MMM",
        dayFormat = "D",
        ignoreCurrentYear = true
    }: DateFormatOptions = {}
): string => {
    const start: dayjs.Dayjs = dayjs.isDayjs(startDate)
        ? (startDate as dayjs.Dayjs)
        : dayjs(startDate);
    const end: dayjs.Dayjs | null = endDate
        ? dayjs.isDayjs(endDate)
            ? (endDate as dayjs.Dayjs)
            : dayjs(endDate)
        : null;
    const currentYear: number = dayjs().year();

    const shouldShowYearForDate = (date: dayjs.Dayjs) => {
        return date.year() !== currentYear || !ignoreCurrentYear;
    };

    if (!end) {
        return `${start.format(`${monthFormat} ${dayFormat}${shouldShowYearForDate(start) ? ", " + yearFormat : ""}`)} onwards`;
    }

    if (start.isSame(end, "day")) {
        return start.format(
            `${monthFormat} ${dayFormat}${shouldShowYearForDate(start) ? ", " + yearFormat : ""}`
        );
    }

    const sameYear: boolean = start.year() === end.year();
    const sameMonth: boolean = start.month() === end.month() && sameYear;

    if (!sameYear) {
        const startFormat = `${monthFormat} ${dayFormat}${shouldShowYearForDate(start) ? ", " + yearFormat : ""}`;
        const endFormat = `${monthFormat} ${dayFormat}${shouldShowYearForDate(end) ? ", " + yearFormat : ""}`;
        return `${start.format(startFormat)} ${separator} ${end.format(endFormat)}`;
    }

    if (sameMonth) {
        const startFormat = `${monthFormat} ${dayFormat}`;
        const endFormat = shouldShowYearForDate(end)
            ? `${dayFormat}, ${yearFormat}`
            : dayFormat;
        return `${start.format(startFormat)} ${separator} ${end.format(endFormat)}`;
    }

    const yearSuffix = shouldShowYearForDate(end)
        ? `, ${end.format(yearFormat)}`
        : "";
    return `${start.format(`${monthFormat} ${dayFormat}`)} ${separator} ${end.format(`${monthFormat} ${dayFormat}`)}${yearSuffix}`;
};

const dateFormatToString = (date: string) => {
    return dayjs(date).format("MMM D, YYYY");
};

const notAvailable = () => {
    return "N/A";
};

const camelToSentence = (camelStr: string): string => {
    return camelStr
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

export {
    capitalizeFirstLetter,
    cutText,
    formatDate,
    formatTime,
    formatCurrency,
    diffTimeByNow,
    isset,
    onlyNumber,
    randomNumbers,
    timeAgo,
    timeIntervalsWithTz,
    toRaw,
    toRGB,
    slideDown,
    slideUp,
    stringToHTML,
    capitalizeFirstLetters,
    unformatString,
    messageAlerts,
    formatPHPDate,
    formatDateRange,
    dateFormatToString,
    notAvailable,
    camelToSentence
};
