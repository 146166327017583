<script lang="ts" setup>
    import { ref } from "vue";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import Skeleton from "primevue/skeleton";

    const props = defineProps({
        columns: {
            type: Number,
            default: 4
        },
        rows: {
            type: Number,
            default: 7
        },
        withCheckBox: {
            type: Boolean,
            default: false
        }
    });

    const items = ref(new Array(props.rows));
</script>

<template>
    <DataTable :value="items">
        <Column
            v-if="withCheckBox"
            class="w-0.5 text-center"
            selectionMode="multiple"
        />
        <Column v-for="n in columns">
            <template #header>
                <Skeleton></Skeleton>
            </template>
            <template #body>
                <Skeleton></Skeleton>
            </template>
        </Column>
    </DataTable>
</template>
