<script setup lang="ts">
    import { provide, reactive, ref } from "vue";
    import { useEmployeesStore } from "../../../stores/employeesStore";
    import {
        NotificationElement, BaseNotification
    } from "../../../base-components/Notification";
    import { useRoute } from "vue-router";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import Lucide from "../../../base-components/Lucide";
    import Modal from "../../../base-components/Dialog/Modal.vue";
    import Button from "../../../base-components/Button";
    import BaseButton from "@/base-components/Button/BaseButton.vue";
    import Tippy from "@/base-components/Tippy/Tippy.vue";

    const loading = ref(false);
    const route = useRoute();
    const dialog = ref(false);
    const EmployeeStore = useEmployeesStore();
    const backendValidationErrors = ref({});
    const errorNotification = ref<NotificationElement>();
    const successNotification = ref<NotificationElement>();
    const form = reactive({
        attachment: null as File | null
    });

    const $externalResults = ref({});
    const rules = {
        attachment: {
            required
        }
    };

    const validate = useVuelidate(rules, form, {
        $externalResults
    });

    const open = () => {
        dialog.value = true;
    };

    const close = () => {
        dialog.value = false;
        $externalResults.value = {};
        validate.value.$reset();
    };

    const download = async () => {
        await EmployeeStore.downloadEmployeeShiftImportTemplate();
    };

    const errorNotificationToggle = () => {
        // Show notification
        errorNotification.value?.showToast();
    };
    provide(
        "bind[errorNotification]",
        (el: NotificationElement) => {
            errorNotification.value = el;
        }
    );
    const showErrorsNotification = () => {
        errorNotificationToggle();
    };

    const successNotificationToggle = () => {
        successNotification.value?.showToast();
    };
    provide(
        "bind[successNotification]",
        (el: NotificationElement) => {
            successNotification.value = el;
        }
    );
    const showSuccessNotification = () => {
        successNotificationToggle();
    };

    const uploadFile = (e: Event) => {
        const target = e.target as HTMLInputElement;

        if (!target.files) {
            return;
        }

        form.attachment = target.files[0];
    };

    const onClickUpload = async () => {
        loading.value = true;
        validate.value.$reset();
        validate.value.$clearExternalResults();
        validate.value.$touch();

        if (validate.value.$invalid) {
            backendValidationErrors.value = {
                message: ["Incomplete or Missing required data"]
            };

            showErrorsNotification();
            loading.value = false;
            return;
        }

        if (!validate.value.$invalid) {
            try {
                const fd = new FormData();
                fd.append(
                    "attachment",
                    form.attachment as File
                );

                await EmployeeStore.importEmployeeShift(fd);

                close();
                showSuccessNotification();
            } catch (error: any) {
                backendValidationErrors.value = {
                    message: ["Something went wrong."]
                };

                showErrorsNotification();

                $externalResults.value =
                    error?.response?.data?.errors;
            } finally {
                loading.value = false;
            }
        }
    };
</script>

<template>
    <Modal :open="dialog" size="lg">
        <template #trigger>
            <BaseButton
                variant="secondary"
                kind="icon"
                @click="open"
                icon="FolderDown"
                tippy-content="Import Employee Shifts"
            >
            </BaseButton>
        </template>

        <template #content>
            <div
                class="flex items-center border-b border-gray-400/50
                    dark:border-darkmode-400"
            >
                <h2 class="mb-4 mr-auto text-base font-medium">Select File</h2>
            </div>

            <div
                v-if="
                    Object.keys($externalResults)?.length >
                    0
                "
                class="mt-4 rounded bg-red-200 px-4 text-red-600"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li v-for="error in $externalResults">
                        {{ error[0] }}
                    </li>
                </ul>
            </div>

            <div class="mt-4">
                <p>
                    <input
                        id="uploadFileBtn"
                        class="btn mr-2 shadow-md"
                        type="file"
                        @change="uploadFile"
                    />
                </p>
                <template v-if="validate.attachment.$error">
                    <div
                        v-for="(error, index) in validate
                            .attachment.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </template>

        <template #action>
            <div class="flex flex-col sm:flex-row gap-2 justify-between">
                <div>
                    <BaseButton
                        variant="secondary"
                        @click="download"
                    >
                        Download Template
                    </BaseButton>
                </div>

                <div class="flex flex-col sm:flex-row gap-2 justify-end">
                    <Button
                        class="w-full sm:w-24"
                        type="button"
                        variant="outline-secondary"
                        :disabled="loading"
                        @click="close"
                    >
                        Close
                    </Button>

                    <Button
                        id="btn_process"
                        class="w-full sm:w-32"
                        variant="customPrimary"
                        :disabled="loading"
                        @click="onClickUpload"
                    >
                        <div v-if="loading" class="flex items-center">
                            <svg
                                aria-hidden="true"
                                class="mr-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200
                                    dark:text-gray-600"
                                fill="none"
                                viewBox="0 0 100 101"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>

                            <div>Uploading...</div>
                        </div>

                        <div v-else>Upload</div>
                    </Button>
                </div>
            </div>
        </template>
    </Modal>

    <BaseNotification
        class="flex"
        refKey="successNotification"
    >
        Employees has been imported successfully.
    </BaseNotification>

    <BaseNotification
        type="error"
        class="flex"
        refKey="errorNotification"
    >
        <ul class="list-disc list-inside">
            <template v-for="errorBag in backendValidationErrors">
                <li>
                    {{ errorBag[0] }}
                </li>
            </template>
        </ul>
    </BaseNotification>
</template>
