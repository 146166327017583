<script setup lang='ts'>
    import { computed } from "vue";
    import * as lucideIcons from "lucide-vue-next";
    export type Icon = keyof typeof lucideIcons;

    const props = withDefaults(defineProps<{
        icon?: Icon;
        size?: "sm" | "md" | "lg";
        variant?: "primary" | "secondary" | "tertiary" | "success" | "danger" | "info" | "white";
        label?: string;
    }>(), { 
        icon: "Users",
        size: "lg",
        variant: "tertiary",
        label: "Loading"
    })

    const loaderSize = computed(() => 
        {
            if(props.size === "sm") {
                return {
                    loaderSize: "size-10",
                    iconSize: "size-5 text-[10px]",
                    labelSize: "text-[10px]"
                }
            } else if(props.size === "md") {
                return {
                    loaderSize: "size-20",
                    iconSize: "size-10",
                    labelSize: "text-xs"
                }
            } else {
                return {
                    loaderSize: "size-28",
                    iconSize: "size-14",
                    labelSize: "text-xs"
                }
            }
        }
    )

    const loaderColor = computed(() => {
        if(props.variant === "primary") {
            return "#183054"
        } else if(props.variant === "secondary") {
            return "#4F87A7"
        } else if(props.variant === "tertiary") {
            return "#0C97B4"
        } else if(props.variant === "success") {
            return "#5AB920"
        } else if(props.variant === "danger") {
            return "#D15555"
        } else if(props.variant === "white") {
            return "#FFFFFF"
        } else {
            return "#E0A221"
        }
    })

</script>

<template>
    <div class="flex flex-col items-center justify-center">
        <div class="relative flex-shrink-0"
            :class="loaderSize?.loaderSize">
            <svg
            width="25"
            viewBox="-2 -2 42 42"
            xmlns="http://www.w3.org/2000/svg"
            :stroke="loaderColor"
            class="w-full h-full"
            
            >
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="4">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                    />
                </path>
                </g>
            </g>
            </svg>
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                <component
                    :is="lucideIcons[icon]"
                    class="stroke-2 animate-pulse"
                    :class="[loaderSize?.iconSize, `!text-[${loaderColor}]`]"
                    :style="{ color: loaderColor }"
                />
            </div>
        </div>
        <p class="text-slate-400 font-medium text-xs mt-1"
            :class="[loaderSize.labelSize, { '!text-white': props.variant === 'white' }]">{{ label }}</p>
    </div>
</template>