<script setup lang='ts'>
    import { computed } from 'vue';
    import Lucide from '@/base-components/Lucide';
    import * as lucideIcons from "lucide-vue-next";

    export type Icon = keyof typeof lucideIcons;

    const props = withDefaults(defineProps<{
        message?: string;
        icon?: Icon;
        size?: string;
        height?: string;
    }>(), {
        message: 'No data found',
        icon: 'CircleOff',
        size: 'sm',
        height: '150',
    });

    interface SizeClass {
        iconSize: string;
        textSize: string;
    }

    const sizeClass = computed(() => {
        let sizeClass: SizeClass = {
            iconSize: 'size-4',
            textSize: 'text-sm',
        };
        switch (props.size) {
            case 'sm':
                sizeClass = {
                    iconSize: 'size-4',
                    textSize: 'text-sm',
                }
                break;
            case 'md':
                sizeClass = {
                    iconSize: 'size-6',
                    textSize: 'text-base',
                }
                break;
            case 'lg':
                sizeClass = {
                    iconSize: 'size-8',
                    textSize: 'text-xl',
                }
                break;
            default:
                sizeClass = {
                    iconSize: 'size-4',
                    textSize: 'text-sm',
                }
                break;
        }

        return sizeClass;
    });

</script>

<template>
    <div
        class="flex flex-col items-center justify-center gap-2 text-slate-400 w-full text-center sm:text-left sm:flex-row"
        :style="{ minHeight: `${height}px` }"
    >

        <component
                    :is="lucideIcons[icon]"
            :class="sizeClass.iconSize"
        />
        <p :class="sizeClass.textSize">
            {{ message }}
        </p>
    </div>
</template>