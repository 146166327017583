<script lang="ts">
    export default {
      inheritAttrs: false,
    };
</script>

<script setup lang="ts">
    import _ from "lodash";
    import { twMerge } from "tailwind-merge";
    import {
      computed,
      withDefaults,
      InputHTMLAttributes,
      useAttrs,
      inject,
      watch,
      ref,
    } from "vue";
    import { ProvideFormInline } from "./FormInline.vue";
    import { ProvideInputGroup } from "./InputGroup/InputGroup.vue";
    import Lucide from "../Lucide/Lucide.vue";
    import { Icon } from "@/base-components/Lucide/Lucide.vue";
    import Tippy from "@/base-components/Tippy/Tippy.vue";
    import { Proportions } from "lucide-vue-next";

    interface FormInputProps extends InputHTMLAttributes {
      modelValue?: InputHTMLAttributes["value"];
      formInputSize?: "sm" | "lg";
      rounded?: boolean;
      validatedError?: string | string[];
      withValidation?: boolean;
      id?: string;
      textMaxLength?: number;
      inputCurrency?: string;
      helperText?: string;
      insideLabel?: string;
      rightIcon?: Icon;
      type?: string;
      loading?: boolean;
      close?: boolean;
      for?: 'search';
      placeholder?: string;
    }

    interface FormInputEmit {
      (e: "update:modelValue", value: string): void;
      (e: "close"): void;
    }

    const props = withDefaults(defineProps<FormInputProps>(),
    {
        withValidation: false,
        loading: false,
    });

    const attrs = useAttrs();

    const formInline = inject<ProvideFormInline>("formInline", false);
    const inputGroup = inject<ProvideInputGroup>("inputGroup", false);

    const modelValueLength = computed(() => {
        return props.modelValue ? String(props.modelValue).length : 0;
    });

    // Check if the first word is "search"
    const isFirstWordSearch = computed(() => {
        if (!props.placeholder) return false;
        const str = props.placeholder.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '')
        const firstWord = str.split(' ')[0].toLowerCase();
        return firstWord.includes('search') || firstWord.includes('search:');
    });


    const computedClass = computed(() =>
      twMerge([
        "disabled:bg-gray-50 disabled:text-slate-300 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent",
        "[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent",
        "transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90",
        "focus:ring-2 focus:ring-custom-tertiary focus:ring-opacity-50 focus:border-custom-tertiary focus:border-opacity-40",
        "dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80",
        props.formInputSize == "sm" && "text-xs py-1.5 px-2",
        props.formInputSize == "lg" && "text-lg py-1.5 px-4",
        props.rounded && "rounded-full",
        formInline && "flex-1",
        inputGroup &&
          "rounded-none [&:not(:first-child)]:border-l-transparent first:rounded-l last:rounded-r z-10",
        typeof attrs.class === "string" && attrs.class,
        props.validatedError && "!border-custom-muted-red text-custom-muted-red !ring-red-100 placeholder:text-red-300",
        props.textMaxLength === modelValueLength.value && "!border-custom-muted-red text-custom-muted-red !ring-red-100",
        props.inputCurrency?.length && "pr-12",
        props.insideLabel?.length && "pl-12",
        (props.rightIcon && props.type === "date") && "peer [&::-webkit-calendar-picker-indicator]:opacity-0",
        (props.rightIcon && props.type !== "date") && "pr-9",
        !isFirstWordSearch.value && props.for === "search" && "placeholder:text-custom-tertiary placeholder:font-medium",
      ])
    );

    const localValue = ref(props.modelValue);
    const emit = defineEmits<FormInputEmit>();

    watch(localValue, () => {
      emit("update:modelValue", localValue.value);
    });
</script>

<template>
    <div>
        <div class="relative flex">
            <input
                :class="computedClass"
                :type="props.type"
                v-bind="_.omit(attrs, 'class')"
                v-model="localValue"
                :maxlength="textMaxLength"
                :id="id"
                :placeholder="placeholder"
            />
            <div v-if="props.inputCurrency?.length"
                class=" absolute right-3 text-slate-400 font-medium -translate-y-1/2 top-1/2">
                {{ props.inputCurrency }}
            </div>
            <div v-if="props.insideLabel?.length"
                class="absolute left-3 text-slate-300 font-bold text-xs -translate-y-1/2 top-1/2 pointer-events-none">
                {{ props.insideLabel }}
            </div>
            <div v-if="props.rightIcon && !props.loading && !props.close"
                class="absolute right-3 text-custom-tertiary font-bold text-xs -translate-y-1/2 top-1/2 pointer-events-none">
                <Lucide :icon="props.rightIcon" class="size-4" />
            </div>
            <div v-if="props.loading"
                class="absolute right-3 text-custom-tertiary font-bold text-xs -translate-y-1/2 top-1/2 pointer-events-none">
                <svg 
                    class="animate-spin h-4 w-4" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24"
                >
                    <circle 
                        class="opacity-25" 
                        cx="12" 
                        cy="12" 
                        r="10" 
                        stroke="currentColor" 
                        stroke-width="4"
                    ></circle>
                    <path 
                        class="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
            <Tippy
                v-if="props.close && !props.loading"
                content="
                    <div>
                        <span class='font-medium text-[10px] text-slate-400'>Action:</span>
                        <br>
                        <span class='font-bold text-slate-500'>Clear Filters</span>
                    </div>
                "
                :options="{
                    allowHTML: true,
                    theme: 'light',
                }"
                class="absolute right-3 text-custom-tertiary font-bold text-xs -translate-y-1/2 top-1/2">
                <Lucide icon="CircleX" class="size-4 cursor-pointer" @click="emit('close')" />
            </Tippy>
        </div>
        <div v-if="helperText"
            class="mt-1 text-xs text-gray-500">
            {{ helperText }}
        </div>
        <div v-if="withValidation || textMaxLength"
            class="flex justify-between items-center"
            :class="{'!justify-end': !withValidation}">
            <div v-if="withValidation" class="h-5 mt-1">
                <div
                    v-if="validatedError"
                    class="flex items-center gap-1 text-custom-muted-red"
                >
                    <Lucide icon="TriangleAlert" class="size-4" />
                    <span>{{ typeof validatedError === 'string' ?  validatedError : validatedError[0] }}</span>
                </div>
            </div>
            <div v-if="textMaxLength" class="text-slate-500 mt-1"
                :class="`${modelValueLength === textMaxLength ? '!text-custom-muted-red' : ''}`">
                {{`${modelValueLength} / ${textMaxLength}`}}
            </div>
        </div>
    </div>
</template>
